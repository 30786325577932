import CloseIcon from '@mui/icons-material/Close';
import {
    Modal,
    Typography,
    Button,
    Box,
    Paper,
    Grow,
    Fade,
    useTheme,
    IconButton,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface TasksExceedLimitModalProps {
    open: boolean;
    onClose: () => void;
}

const TasksExceedLimitModal: React.FC<TasksExceedLimitModalProps> = ({
    open,
    onClose,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleClick = () => {
        onClose();
        navigate('/tasks');
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="tasks-exceed-limit-modal-title"
            aria-describedby="tasks-exceed-limit-modal-description"
            data-testid="tasks-exceed-limit-modal"
        >
            <>
                <Box
                    sx={{
                        width: '400px',
                        maxWidth: '90%',
                        margin: 'auto',
                        position: 'relative',
                        display: 'block',
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }}
                >
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                        }}
                        aria-label="Close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Paper elevation={3} sx={{ padding: 3 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Fade in={open} timeout={500}>
                                <Typography
                                    id="tasks-exceed-limit-modal-title"
                                    variant="h6"
                                    sx={{ my: 4 }}
                                    color={theme.palette.text.primary}
                                    align="center"
                                >
                                    You have reached the maximum number of items
                                    for free users.
                                </Typography>
                            </Fade>
                            <Grow in={open} timeout={500}>
                                <img
                                    src="/illust/no-subs.webp"
                                    alt="Tasks exceed limit"
                                    style={{
                                        width: '240px',
                                        maxWidth: '80%',
                                        margin: 'auto',
                                    }}
                                />
                            </Grow>{' '}
                            <Fade in={open} timeout={500}>
                                <Typography
                                    id="tasks-exceed-limit-modal-title"
                                    variant="h6"
                                    sx={{ mt: 2 }}
                                    color={theme.palette.text.primary}
                                    align="center"
                                >
                                    Please check our <b>subscription plans</b>{' '}
                                    and choose the one that best fits your
                                    needs:
                                </Typography>
                            </Fade>
                            <Fade in={open} timeout={1000}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ my: 2 }}
                                    onClick={handleClick}
                                    data-testid="button-accept"
                                    aria-label="Go to Subscriptions"
                                >
                                    Go to Subscriptions
                                </Button>
                            </Fade>
                        </Box>
                    </Paper>
                </Box>
            </>
        </Modal>
    );
};

export default TasksExceedLimitModal;

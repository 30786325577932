import { logout, ThemeToggle } from '@gagovictor/shared-frontend-core';
import { RootState } from '@gagovictor/shared-frontend-core/dist/store';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PolicyIcon from '@mui/icons-material/Policy';
import TaskIcon from '@mui/icons-material/Task';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resetTasksState } from '../../tasks/redux/tasksSlice';

interface NavItem {
    text: string;
    icon: React.ReactNode;
    route: string;
    experimental?: boolean;
    comingSoon?: boolean;
}

export function Sidebar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const isAuthenticated = useSelector(
        (state: RootState) => state.auth.isAuthenticated
    );

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const handleLogoutClick = () => {
        dispatch(resetTasksState());
        dispatch(logout());
        navigate('/logout');
    };

    const authenticatedNavItems: NavItem[] = [
        {
            text: 'Tasks',
            icon: <TaskIcon />,
            route: '/tasks',
        },
        {
            text: 'Archived Tasks',
            icon: <ArchiveIcon />,
            route: '/tasks/archived',
        },
        {
            text: 'Board',
            icon: <DashboardIcon />,
            route: '/tasks/board',
            experimental: true,
        },
        {
            text: 'Notes',
            icon: <TextSnippetIcon />,
            route: '/notes',
        },
        {
            text: 'Archived Notes',
            icon: <ArchiveIcon />,
            route: '/notes/archived',
        },
    ];

    const unauthenticatedNavItems: NavItem[] = [
        {
            text: 'Login',
            icon: <LoginIcon />,
            route: '/login',
        },
    ];

    const accountNavItems: NavItem[] = [
        {
            text: 'Account',
            icon: <AccountCircleIcon />,
            route: '/account',
        },
        {
            text: 'Your Subscription',
            icon: <CardMembershipIcon />,
            route: '/purchase',
        },
    ];

    const fixedNavItems: NavItem[] = [
        {
            text: 'Privacy Policy',
            icon: <PolicyIcon />,
            route: '/privacy-policy',
        },
    ];

    const DrawerList = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
        >
            <List>
                {isAuthenticated &&
                    authenticatedNavItems.map((item) => (
                        <ListItem key={item.text} disablePadding>
                            <ListItemButton
                                onClick={() => navigate(item.route)}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText
                                    primary={item.text}
                                    secondary={
                                        item.comingSoon
                                            ? 'Coming Soon'
                                            : item.experimental
                                              ? 'Beta'
                                              : undefined
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>

            {isAuthenticated && authenticatedNavItems.length && <Divider />}

            {/* Account-Related Navigation Items */}
            <List>
                {isAuthenticated &&
                    accountNavItems.map((item) => (
                        <ListItem key={item.text} disablePadding>
                            <ListItemButton
                                onClick={() => navigate(item.route)}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                {/* Theme Toggle */}
                {isAuthenticated && (
                    <ListItem>
                        <ThemeToggle />
                    </ListItem>
                )}
            </List>

            {isAuthenticated && <Divider />}

            <List>
                {/* Unauthenticated Navigation Items */}
                {!isAuthenticated &&
                    unauthenticatedNavItems.map((item) => (
                        <ListItem key={item.text} disablePadding>
                            <ListItemButton
                                onClick={() => navigate(item.route)}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText
                                    primary={item.text}
                                    secondary={
                                        item.experimental ? 'Beta' : undefined
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}

                {/* Fixed Navigation Items */}
                {fixedNavItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton onClick={() => navigate(item.route)}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}

                {/* Logout Button */}
                {isAuthenticated && (
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleLogoutClick}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
        </Box>
    );

    return (
        <div>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer(true)}
            >
                <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}

import { PullToRefresh } from '@gagovictor/shared-frontend-core/dist/shared/components/PullToRefresh';
import { useAutoRefresh } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useAutoRefresh';
import { useInfiniteScroll } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useInfiniteScroll';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import Masonry from '@mui/lab/Masonry';
import {
    Alert,
    Fab,
    Container,
    Snackbar,
    Button,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    InputAdornment,
    Collapse,
    Grow,
    useMediaQuery,
    useTheme,
    Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { RefreshableProps } from '../../shared/models/refreshable';
import { setSortOption } from '../../shared/redux/preferencesSlice';
import NoteCard from '../components/NoteCard';
import NoteModal from '../components/NoteModal';
import { Note, FetchNotesParams } from '../models/note';
import { fetchNotesAsync } from '../redux/notesSlice';

const NotesPage = ({ setRefreshAction }: RefreshableProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { notes, fetchStatus, fetchError, hasMore } = useSelector(
        (state: RootState) => state.notes
    );
    const theme = useTheme(); // Access the theme object for breakpoints
    const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Hook moved to the top level
    const { sortOption } = useSelector((state: RootState) => state.preferences);
    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedNote, setSelectedNote] = useState<Note>();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
    const [snackbarSeverity, setSnackbarSeverity] = useState<
        'success' | 'error'
    >('success');
    const [snackbarUndoAction, setSnackbarUndoAction] = useState<
        (() => void) | undefined
    >(undefined);
    const [filterText, setFilterText] = useState<string>('');
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [fetchParams, setFetchParams] = useState<FetchNotesParams>({
        page: 1,
        limit: 20,
        filters: {
            archived: false,
        },
    });

    const loadMore = useCallback(() => {
        if (fetchStatus !== 'loading' && hasMore) {
            setFetchParams((prev) => ({
                ...prev,
                page: prev.page + 1,
            }));
        }
    }, [fetchStatus, hasMore]);

    const sentinelRef = useInfiniteScroll({
        loading: fetchStatus === 'loading',
        hasMore,
        onLoadMore: loadMore,
    });

    const fetchNotes = useCallback(async () => {
        await dispatch(fetchNotesAsync(fetchParams));
    }, [dispatch, fetchParams]);

    useEffect(() => {
        dispatch(fetchNotesAsync(fetchParams));
    }, [fetchParams, dispatch]);

    useEffect(() => {
        setRefreshAction(() => fetchNotes);

        return () => {
            setRefreshAction(undefined);
        };
    }, [fetchNotes, setRefreshAction]);

    useAutoRefresh({
        onRefresh: fetchNotes,
        interval: 60000,
        immediate: false,
        onlyWhenFocused: true,
    });

    const handleCreateNote = () => {
        setEditMode(false);
        setModalOpen(true);
    };

    const handleEditNote = (note: Note) => {
        setSelectedNote(note);
        setEditMode(true);
        setModalOpen(true);
    };

    const onCloseNoteModal = () => {
        setEditMode(false);
        setModalOpen(false);
    };

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const showSnackbar = (
        message: string,
        severity: 'success' | 'error',
        undoAction?: () => void
    ) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
        setSnackbarUndoAction(() => undoAction);
    };

    const handleSnackbarAction = () => {
        if (snackbarUndoAction) {
            snackbarUndoAction();
        }
    };

    const lowerCaseFilterText = filterText?.toLowerCase() || '';

    const filteredNotes = notes
        .filter((note: Note) => {
            const matchesText = filterText
                ? note.title.toLowerCase().includes(lowerCaseFilterText) ||
                  note.content.toLowerCase().includes(lowerCaseFilterText) ||
                  note.tags
                      .map((tag) => tag.toLowerCase())
                      .includes(lowerCaseFilterText)
                : true;
            return !note.archivedAt && !note.deletedAt && matchesText;
        })
        .sort((a, b) => {
            const getCreatedTime = (note: Note): number => {
                const time = new Date(note.createdAt).getTime();
                return isNaN(time) ? 0 : time;
            };

            switch (sortOption) {
                case 'createdAsc':
                    return getCreatedTime(a) - getCreatedTime(b);
                case 'createdDesc':
                    return getCreatedTime(b) - getCreatedTime(a);
                default:
                    return 0;
            }
        });

    const handleClearSearch = () => setFilterText('');

    const toggleFilters = () => {
        setFiltersVisible((prev) => !prev); // Toggle filters visibility
    };

    return (
        <>  
            <PullToRefresh onRefresh={fetchNotes}>
                <Container
                    sx={{
                        width: '100%',
                        minHeight: 'calc(100vh - 64px)',
                        marginTop: { xs: '56px', md: '64px' },
                        position: 'relative',
                        py: '32px',
                        gap: 4,
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            fontFamily:
                                '"Uncial Antiqua", "Helvetica", "Arial", sans-serif',
                            letterSpacing: '0.05em',
                            background: `linear-gradient(-60deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            backgroundSize: '200% 200%',
                            animation: 'logoGradientAnimation 20s ease infinite',
                            '@keyframes logoGradientAnimation': {
                                '0%': { backgroundPosition: '0% 50%' },
                                '50%': { backgroundPosition: '100% 50%' },
                                '100%': { backgroundPosition: '0% 50%' },
                            },
                        }}
                    >
                        Notes
                    </Typography>

                    <Box sx={{ mb: 2 }}>
                        <Button
                            startIcon={
                                filtersVisible ? (
                                    <CloseIcon />
                                ) : (
                                    <FilterListIcon />
                                )
                            }
                            onClick={toggleFilters}
                            fullWidth
                            sx={{ display: { xs: 'flex', md: 'none' } }} // Only show the button on mobile
                            aria-label={
                                filtersVisible ? 'Hide Filters' : 'Filter'
                            }
                        >
                            {filtersVisible ? 'Hide Filters' : 'Filter'}
                        </Button>

                        <Collapse in={filtersVisible || isDesktop}>
                            {/* Show filters based on visibility */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: { sm: 'stretch', md: 'center' },
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap',
                                    gap: window.innerWidth >= 600 ? 2 : 0,
                                    flexDirection: { sm: 'column', md: 'row' },
                                }}
                            >
                                {/* Search Filter */}
                                <Box
                                    sx={{
                                        flex: 1,
                                        minWidth: 200,
                                        position: 'relative',
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label="Search"
                                        variant="outlined"
                                        value={filterText}
                                        onChange={(e) =>
                                            setFilterText(e.target.value)
                                        }
                                        InputProps={{
                                            endAdornment: filterText && (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="clear search"
                                                        edge="end"
                                                        onClick={
                                                            handleClearSearch
                                                        }
                                                    >
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>

                                {/* Sorting */}
                                <Box
                                    sx={{
                                        flex: 1,
                                        minWidth: 200,
                                        position: 'relative',
                                    }}
                                >
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Sort By</InputLabel>
                                        <Select
                                            value={sortOption}
                                            onChange={(e) => {
                                                const newSortOption = e.target
                                                    .value as
                                                    | 'createdAsc'
                                                    | 'createdDesc'
                                                    | 'dueAsc'
                                                    | 'dueDesc';
                                                dispatch(
                                                    setSortOption(newSortOption)
                                                ); // Store in preferences
                                            }}
                                            label="Sort By"
                                        >
                                            <MenuItem value="createdAsc">
                                                Date Created (Oldest First)
                                            </MenuItem>
                                            <MenuItem value="createdDesc">
                                                Date Created (Newest First)
                                            </MenuItem>
                                            <MenuItem value="dueAsc">
                                                Due Date (Earliest First)
                                            </MenuItem>
                                            <MenuItem value="dueDesc">
                                                Due Date (Latest First)
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>

                    {fetchStatus === 'failed' && (
                        <Alert
                            severity="error"
                            data-testid="fetch-error-alert"
                            sx={{ mb: 4 }}
                        >
                            {fetchError}
                            {filteredNotes.length > 0 && (
                                <>
                                    Notes shown may not reflect their current
                                    state.{' '}
                                </>
                            )}
                        </Alert>
                    )}

                    {fetchStatus === 'succeeded' &&
                        filteredNotes.length === 0 && (
                            <Alert severity="info" sx={{ mb: 4, flex: 1 }}>
                                No notes match the filter criteria.
                            </Alert>
                        )}

                    {(fetchStatus === 'succeeded' ||
                        filteredNotes.length > 0) && (
                        <Masonry
                            columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                            spacing={1}
                            data-testid="masonry"
                            sx={{ mb: 4 }}
                        >
                            {filteredNotes.length > 0 &&
                                filteredNotes.map(
                                    (note: Note, index: number) => (
                                        <Grow
                                            key={note.id}
                                            in={true}
                                            timeout={(index + 1) * 300} // Staggered delay for animation
                                        >
                                            <Box data-testid="note-card">
                                                <NoteCard
                                                    note={note}
                                                    onEdit={() =>
                                                        handleEditNote(note)
                                                    }
                                                    showSnackbar={showSnackbar}
                                                />
                                            </Box>
                                        </Grow>
                                    )
                                )}
                        </Masonry>
                    )}

                    {/* Sentinel Element for Infinite Scroll */}
                    <div ref={sentinelRef} />
                </Container>
            </PullToRefresh>
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={handleCreateNote}
            >
                <AddIcon />
            </Fab>

            <NoteModal
                open={modalOpen}
                onClose={onCloseNoteModal}
                note={selectedNote}
                mode={editMode ? 'edit' : 'create'}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    action={
                        snackbarUndoAction && (
                            <Button
                                color="inherit"
                                onClick={handleSnackbarAction}
                                aria-label="Undo"
                            >
                                Undo
                            </Button>
                        )
                    }
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default NotesPage;

import { Box } from '@mui/material';
import { useSprings, animated, to as interpolate } from '@react-spring/web';
import React, { useState, useEffect, useCallback } from 'react';
import { useDrag } from 'react-use-gesture';

import MockTaskCard from './MockTaskCard';

const tasks = [
    {
        id: '1',
        userId: 'user1',
        title: 'Privacy-First',
        description:
            'Cryptography-safe and ad-free — your data belongs to you.',
        status: 'active',
        createdAt: new Date().toISOString(),
        dueDate: null,
        archivedAt: null,
        deletedAt: null,
        checklist: [],
    },
    {
        id: '2',
        userId: 'user2',
        title: 'Tasks & Notes',
        description: 'Everything you need in one place.',
        status: 'active',
        createdAt: new Date().toISOString(),
        dueDate: null,
        archivedAt: null,
        deletedAt: null,
        checklist: [],
    },
    {
        id: '3',
        userId: 'user3',
        title: 'Smart Reminders',
        description: 'Stay on top of your priorities effortlessly.',
        status: 'completed',
        createdAt: new Date().toISOString(),
        dueDate: new Date().toISOString(),
        archivedAt: null,
        deletedAt: null,
        checklist: [],
    },
    {
        id: '4',
        userId: 'user4',
        title: 'Customizable Views',
        description: 'Tailor the app to fit your workflow.',
        status: 'archived',
        createdAt: new Date().toISOString(),
        dueDate: null,
        archivedAt: new Date().toISOString(),
        deletedAt: null,
        checklist: [],
    },
    {
        id: '5',
        userId: 'user5',
        title: 'Cross-Platform Access',
        description: 'Manage your productivity wherever you are.',
        status: 'active',
        createdAt: new Date().toISOString(),
        dueDate: null,
        archivedAt: null,
        deletedAt: null,
        checklist: [],
    },
].reverse(); // Ensure the first card appears on top.

// Helpers for spring animation
const from = () => ({
    x: 0,
    y: -1000,
    scale: 1.5,
    rot: 0,
});

const to = (i: number) => ({
    x: 0,
    y: 150 + i * -4,
    scale: 1,
    rot: (tasks.length - i - 1) * (i % 2 === 0 ? 5 : -5),
    delay: i * 100,
});

const trans = (r: number, s: number) =>
    `perspective(1500px) rotateX(10deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

const SwipeableDeck: React.FC = () => {
    const [gone, setGone] = useState<Set<number>>(new Set()); // Tracks swiped cards
    const [springs, api] = useSprings(tasks.length, (i) => ({
        ...to(i),
        from: from(),
    }));

    const handleSetGone = useCallback(
        (callback: (prev: Set<number>) => Set<number>) => {
            setGone((prev) => {
                const updatedGone = callback(prev);
                return new Set(updatedGone);
            });
        },
        []
    );

    const bind = useDrag(
        ({
            args: [index],
            down,
            movement: [mx],
            direction: [xDir],
            velocity,
        }) => {
            const trigger = velocity > 0.2; // Trigger swipe if velocity is high enough
            const dir = xDir < 0 ? -1 : 1; // Direction: -1 for left, 1 for right

            if (!down && trigger) {
                handleSetGone((prev) => {
                    const newGone = new Set(prev);
                    newGone.add(index);
                    return newGone;
                });
            }

            api.start((i) => {
                if (index === i) {
                    const isGone = gone.has(index);
                    const x = isGone
                        ? (200 + window.innerWidth) * dir
                        : down
                          ? mx
                          : 0; // Positioning logic
                    const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0); // Rotation logic
                    const scale = down ? 1.1 : 1; // Scale when dragging
                    return {
                        x,
                        rot,
                        scale,
                        delay: undefined,
                        config: {
                            friction: 50,
                            tension: down ? 800 : isGone ? 200 : 500,
                        },
                    };
                } else if (i > index) {
                    // Adjust the position of the next cards to take the place of the swiped one
                    return {
                        ...to(i - 1),
                        delay: undefined,
                    };
                }
                return;
            });
        }
    );

    useEffect(() => {
        if (gone.size === tasks.length) {
            setTimeout(() => {
                gone.clear();
                setGone(new Set());
                api.start((i) => to(i)); // Reset deck
            }, 600);
        }
    }, [gone, api]);

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                userSelect: 'none', // Disables text selection
                overflow: 'hidden',
            }}
        >
            {springs.map(({ x, y, rot, scale }, i) =>
                !gone.has(i) ? ( // Only render cards that have not been swiped away
                    <animated.div
                        key={i}
                        style={{
                            position: 'absolute',
                            x,
                            y,
                            transform: interpolate([rot, scale], trans),
                        }}
                    >
                        <animated.div
                            {...bind(i)}
                            style={{
                                width: '300px',
                                height: '200px',
                                touchAction: 'none', // Ensures swipe gestures work correctly
                                userSelect: 'none', // Disables text selection
                            }}
                        >
                            <MockTaskCard task={tasks[i]} />
                        </animated.div>
                    </animated.div>
                ) : null
            )}
        </Box>
    );
};

export default SwipeableDeck;

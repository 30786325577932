import { Box, Button, Container, useTheme } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SlideView } from '../components/SlideView';
import { setHasViewedWelcomeSlides } from '../redux/preferencesSlice';

interface SlideProps {
    title: string;
    description: string;
}

interface WelcomeSlidesProps {
    slides?: SlideProps[];
    onFinish: () => void;
    buttonLabels?: {
        next?: string;
        back?: string;
        finish?: string;
    };
}

export const WelcomeSlidesPage: React.FC<WelcomeSlidesProps> = ({
    slides = [
        {
            title: 'Welcome to the App!',
            description: "We're excited to have you here.",
        },
        {
            title: 'Manage Your Tasks',
            description: 'Organize and prioritize with ease.',
        },
        {
            title: 'Stay Inspired',
            description: 'Track your goals and make progress daily.',
        },
    ],
    onFinish,
    buttonLabels = { next: 'Next', back: 'Back', finish: 'Get Started' },
}) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const dispatch = useDispatch();
    const theme = useTheme();

    const nextSlide = useCallback(() => {
        if (currentSlide < slides.length - 1) {
            setCurrentSlide((prev) => prev + 1);
        } else {
            dispatch(setHasViewedWelcomeSlides());
            onFinish();
        }
    }, [currentSlide, slides.length, dispatch, onFinish]);

    const previousSlide = useCallback(() => {
        if (currentSlide > 0) {
            setCurrentSlide((prev) => prev - 1);
        }
    }, [currentSlide]);

    const handleSwipe = useCallback(
        (direction: 'left' | 'right') => {
            if (direction === 'left' && currentSlide < slides.length - 1) {
                nextSlide();
            } else if (direction === 'right' && currentSlide > 0) {
                previousSlide();
            }
        },
        [currentSlide, slides.length, nextSlide, previousSlide] // Stable now!
    );

    useEffect(() => {
        let touchStartX = 0;

        const handleTouchStart = (e: TouchEvent) => {
            touchStartX = e.touches[0].clientX;
        };

        const handleTouchEnd = (e: TouchEvent) => {
            const touchEndX = e.changedTouches[0].clientX;
            const deltaX = touchEndX - touchStartX;

            if (Math.abs(deltaX) > 50) {
                if (deltaX < 0) handleSwipe('left');
                else handleSwipe('right');
            }
        };

        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handleSwipe]);

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                minHeight: '100vh',
                overflow: 'hidden',
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                {slides.map((slide, index) => (
                    <SlideView
                        key={index}
                        title={slide.title}
                        description={slide.description}
                        isVisible={index === currentSlide}
                    />
                ))}

                {/* Slide Indicators */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        bottom: 100,
                        gap: 1,
                    }}
                >
                    {slides.map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                background: `linear-gradient(90deg, ${theme.palette.text.primary}, ${theme.palette.text.secondary}, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                transition: 'background 0.3s ease-in-out',
                                backgroundSize: '200% 200%',
                                backgroundPosition:
                                    index === currentSlide
                                        ? '100% 100%'
                                        : '0% 0%',
                            }}
                            data-testid={`indicator-${index}`}
                        />
                    ))}
                </Box>

                {/* Navigation Buttons */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        position: 'absolute',
                        bottom: 20,
                        px: 4,
                    }}
                >
                    <Button
                        onClick={previousSlide}
                        disabled={currentSlide === 0}
                        sx={{
                            opacity: currentSlide === 0 ? 0.5 : 1,
                        }}
                        aria-label={buttonLabels.back}
                    >
                        {buttonLabels.back}
                    </Button>
                    <Button
                        color="primary"
                        onClick={nextSlide}
                        aria-label={
                            currentSlide < slides.length - 1
                                ? buttonLabels.next
                                : buttonLabels.finish
                        }
                    >
                        {currentSlide < slides.length - 1
                            ? buttonLabels.next
                            : buttonLabels.finish}
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

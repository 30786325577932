import CloseIcon from '@mui/icons-material/Close';
import {
    Modal,
    Typography,
    Button,
    Box,
    Paper,
    Grow,
    Fade,
    useTheme,
    IconButton,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

interface TasksClearedModalProps {
    open: boolean;
    onClose: () => void;
}

const TasksClearedModal: React.FC<TasksClearedModalProps> = ({
    open,
    onClose,
}) => {
    const theme = useTheme();
    const { innerWidth, innerHeight } = window;
    const [runConfetti, setRunConfetti] = useState(false);

    useEffect(() => {
        if (open) {
            setRunConfetti(true);
            const timer = setTimeout(() => setRunConfetti(false), 3000); // Stop confetti after 3 seconds
            return () => clearTimeout(timer);
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="tasks-cleared-modal-title"
            aria-describedby="tasks-cleared-modal-description"
            data-testid="tasks-cleared-modal"
        >
            <>
                <Box
                    sx={{
                        width: '400px',
                        maxWidth: '90%',
                        margin: 'auto',
                        position: 'relative',
                        display: 'block',
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }}
                >
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                        }}
                        aria-label="Close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Paper elevation={3} sx={{ padding: 3 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Fade in={open} timeout={500}>
                                <Typography
                                    id="tasks-cleared-modal-title"
                                    variant="h6"
                                    sx={{ my: 4 }}
                                    color={theme.palette.text.primary}
                                    align="center"
                                >
                                    That&apos;s it, you cleared them all.
                                    <br />
                                </Typography>
                            </Fade>
                            <Grow in={open} timeout={500}>
                                <img
                                    src="/illust/well-done.png"
                                    alt="Tasks Cleared"
                                    style={{
                                        width: '240px',
                                        maxWidth: '80%',
                                        margin: 'auto',
                                    }}
                                />
                            </Grow>
                            <Fade in={open} timeout={500}>
                                <Typography
                                    id="tasks-cleared-modal-title"
                                    variant="h6"
                                    sx={{ my: 2 }}
                                    color={theme.palette.text.primary}
                                    align="center"
                                >
                                    Go ahead, take a break, keep zooing it!
                                </Typography>
                            </Fade>
                            <Fade in={open} timeout={1000}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onClose}
                                    sx={{ my: 2 }}
                                    data-testid="button-accept"
                                    aria-label="Got it"
                                >
                                    Got it
                                </Button>
                            </Fade>
                        </Box>
                    </Paper>
                </Box>
                {runConfetti && (
                    <Confetti
                        width={innerWidth}
                        height={innerHeight}
                        confettiSource={{
                            w: 10,
                            h: 10,
                            x: innerWidth / 2,
                            y: innerHeight / 2,
                        }}
                        numberOfPieces={300}
                        gravity={0.6}
                        wind={0}
                        recycle={false}
                        initialVelocityX={20}
                        initialVelocityY={30}
                        style={{ zIndex: -1 }}
                        data-testid="react-confetti"
                    />
                )}
            </>
        </Modal>
    );
};

export default TasksClearedModal;

import { apiClient } from '@gagovictor/shared-frontend-core/dist/shared/services/ApiService';

import { PaginatedResponse } from '../../shared/models/api';
import { FetchNotesParams, Note } from '../models/note';

export const fetchNotes = async (
    token: string,
    params: FetchNotesParams
): Promise<PaginatedResponse<Note>> => {
    const {
        page = 0,
        limit = 10,
        filters = {
            archived: false,
        },
    } = params;

    const query = new URLSearchParams({
        page: page.toString(),
        limit: limit.toString(),
        archived: filters.archived!.toString(),
    });

    const queryString = query.toString() ? `?${query.toString()}` : '';
    const response = await apiClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/notes${queryString}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    return response.data;
};

export interface CreateNoteRequest {
    title: string;
    content: string;
    tags: string[];
}

export const createNote = async (
    request: CreateNoteRequest,
    token: string
): Promise<Note> => {
    const response = await apiClient.post(
        `${process.env.REACT_APP_API_BASE_URL}/notes`,
        request,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    return response.data;
};

export interface UpdateNoteRequest {
    id: string;
    title: string;
    content: string;
    tags: string[];
}

export const updateNote = async (
    request: UpdateNoteRequest,
    token: string
): Promise<Note> => {
    const response = await apiClient.patch(
        `${process.env.REACT_APP_API_BASE_URL}/notes/${request.id}`,
        request,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    return response.data;
};

export const deleteNote = async (noteId: string, token: string) => {
    const response = await apiClient.delete(
        `${process.env.REACT_APP_API_BASE_URL}/notes/${noteId}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    return response.data;
};

export const archiveNote = async (noteId: string, token: string) => {
    const response = await apiClient.post(
        `${process.env.REACT_APP_API_BASE_URL}/notes/${noteId}/archive`,
        null,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    return response.data;
};

export const unarchiveNote = async (noteId: string, token: string) => {
    const response = await apiClient.post(
        `${process.env.REACT_APP_API_BASE_URL}/notes/${noteId}/unarchive`,
        null,
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    return response.data;
};

export interface UpdateNoteStatusRequest {
    id: string;
    status: string /*NoteStatus*/;
}

export const updateNoteStatus = async (
    request: UpdateNoteStatusRequest,
    token: string
) => {
    const response = await apiClient.post(
        `${process.env.REACT_APP_API_BASE_URL}/notes/${request.id}/status`,
        { status: request.status },
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    );
    return response.data;
};

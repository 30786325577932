/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../redux/store';
import { PaginatedResponse } from '../../shared/models/api';
import { FetchNotesParams, Note } from '../models/note';
import {
    archiveNote,
    createNote,
    CreateNoteRequest,
    deleteNote,
    fetchNotes,
    unarchiveNote,
    updateNote,
    UpdateNoteRequest,
} from '../services/NoteService';

import {
    loadNotesFromLocalStorage,
    saveNotesToLocalStorage,
} from './persistNotes';

export interface NotesState {
    notes: Note[];
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    fetchError: string | null;
    hasMore: boolean;
    createStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    createError: string | null;
    updateStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    updateError: string | null;
    deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    deleteError: string | null;
    archiveStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    archiveError: string | null;
}

export const notesInitialState: NotesState = {
    notes: loadNotesFromLocalStorage() || [],
    fetchStatus: 'idle',
    fetchError: null,
    hasMore: true,
    createStatus: 'idle',
    createError: null,
    updateStatus: 'idle',
    updateError: null,
    deleteStatus: 'idle',
    deleteError: null,
    archiveStatus: 'idle',
    archiveError: null,
};

const notesSlice = createSlice({
    name: 'notes',
    initialState: notesInitialState,
    reducers: {
        reorderNotesLocally(
            state,
            action: PayloadAction<{ updatedNotes: Note[] }>
        ) {
            const { updatedNotes } = action.payload;
            state.notes = updatedNotes;

            saveNotesToLocalStorage(state.notes);
        },
        resetNotesState(state) {
            Object.assign(state, notesInitialState);
            saveNotesToLocalStorage([]);
        },
    },
    extraReducers: (builder) => {
        // Fetch Notes
        builder
            .addCase(fetchNotesAsync.pending, (state) => {
                state.fetchStatus = 'loading';
                state.fetchError = null;
                state.notes = loadNotesFromLocalStorage() || [];
            })
            .addCase(fetchNotesAsync.fulfilled, (state, action) => {
                state.fetchStatus = 'succeeded';
                // TODO store archived notes in a separate property so that the notes list is not reset between views.
                let incomingNotes = action.payload.items || [];
                if (action.meta.arg.page === 1) {
                    // If fetching from page, replace the notes
                    state.notes = incomingNotes;
                } else {
                    state.notes.forEach((note, index) => {
                        const updatedNoteIndex = incomingNotes.findIndex(
                            (t) => t.id === note.id
                        );
                        if (updatedNoteIndex > -1) {
                            state.notes[index] =
                                incomingNotes[updatedNoteIndex];
                            incomingNotes.splice(updatedNoteIndex, 1);
                        }
                    });
                    state.notes = [...state.notes, ...incomingNotes];
                }
                // Determine if more notes are available
                state.hasMore =
                    (action.payload.items?.length || 0) >=
                    (action.meta.arg.limit || 20);

                saveNotesToLocalStorage(state.notes);
            })
            .addCase(fetchNotesAsync.rejected, (state) => {
                state.fetchStatus = 'failed';
                state.fetchError = 'Failed to load notes.';
                state.hasMore = false;
            });

        // Create Note
        builder
            .addCase(createNoteAsync.pending, (state) => {
                state.createStatus = 'loading';
            })
            .addCase(
                createNoteAsync.fulfilled,
                (state, action: PayloadAction<Note>) => {
                    state.createStatus = 'succeeded';
                    state.notes.push(action.payload);

                    saveNotesToLocalStorage(state.notes);
                }
            )
            .addCase(createNoteAsync.rejected, (state, action) => {
                state.createStatus = 'failed';
                state.createError =
                    action.error.message || 'Failed to create note.';
            });

        // Update Note
        builder
            .addCase(updateNoteAsync.pending, (state) => {
                state.updateStatus = 'loading';
            })
            .addCase(
                updateNoteAsync.fulfilled,
                (state, action: PayloadAction<Note>) => {
                    state.updateStatus = 'succeeded';
                    const index = state.notes.findIndex(
                        (note) => note.id === action.payload.id
                    );
                    if (index >= 0) {
                        state.notes[index] = action.payload;
                    }

                    saveNotesToLocalStorage(state.notes);
                }
            )
            .addCase(updateNoteAsync.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.updateError =
                    action.error.message || 'Failed to update note.';
            });

        // Delete Note
        builder
            .addCase(deleteNoteAsync.pending, (state) => {
                state.deleteStatus = 'loading';
            })
            .addCase(
                deleteNoteAsync.fulfilled,
                (state, action: PayloadAction<string>) => {
                    state.deleteStatus = 'succeeded';
                    const note = state.notes.find(
                        (note) => note.id === action.payload
                    );
                    if (note) {
                        note.deletedAt = new Date().toISOString();
                    }

                    saveNotesToLocalStorage(state.notes);
                }
            )
            .addCase(deleteNoteAsync.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.deleteError =
                    action.error.message || 'Failed to delete note.';
            });

        // Archive Note
        builder
            .addCase(archiveNoteAsync.pending, (state) => {
                state.archiveStatus = 'loading';
            })
            .addCase(
                archiveNoteAsync.fulfilled,
                (state, action: PayloadAction<string>) => {
                    state.archiveStatus = 'succeeded';
                    const note = state.notes.find(
                        (note) => note.id === action.payload
                    );
                    if (note) {
                        note.archivedAt = new Date().toISOString();
                    }

                    saveNotesToLocalStorage(state.notes);
                }
            )
            .addCase(archiveNoteAsync.rejected, (state, action) => {
                state.archiveStatus = 'failed';
                state.archiveError =
                    action.error.message || 'Failed to archive note.';
            })
            .addCase(unarchiveNoteAsync.pending, (state) => {
                state.archiveStatus = 'loading';
            })
            .addCase(
                unarchiveNoteAsync.fulfilled,
                (state, action: PayloadAction<string>) => {
                    state.archiveStatus = 'succeeded';
                    const note = state.notes.find(
                        (note) => note.id === action.payload
                    );
                    if (note) {
                        note.archivedAt = null;
                    }

                    saveNotesToLocalStorage(state.notes);
                }
            )
            .addCase(unarchiveNoteAsync.rejected, (state, action) => {
                state.archiveStatus = 'failed';
                state.archiveError =
                    action.error.message || 'Failed to unarchive note.';
            });
    },
});

export const fetchNotesAsync = createAsyncThunk<
    PaginatedResponse<Note>,
    FetchNotesParams,
    { state: RootState; rejectValue: any }
>('notes/fetchNotes', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.auth.token;

    try {
        const response = await fetchNotes(token, params);
        return response;
    } catch (error: any) {
        if (error.response?.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({ message: 'Failed to fetch notes' });
    }
});

export const createNoteAsync = createAsyncThunk(
    'notes/createNoteAsync',
    async (note: CreateNoteRequest, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const token = state.auth.token;

        try {
            const response = await createNote(note, token);
            return response;
        } catch (error: any) {
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ message: 'Failed to create note' });
        }
    }
);

export const updateNoteAsync = createAsyncThunk(
    'notes/updateNoteAsync',
    async (note: UpdateNoteRequest, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const token = state.auth.token;

        try {
            const response = await updateNote(note, token);
            return response;
        } catch (error: any) {
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ message: 'Failed to update note' });
        }
    }
);

export const deleteNoteAsync = createAsyncThunk(
    'notes/deleteNoteAsync',
    async (noteId: string, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const token = state.auth.token;

        try {
            await deleteNote(noteId, token);
            return noteId;
        } catch (error: any) {
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ message: 'Failed to delete note' });
        }
    }
);

export const archiveNoteAsync = createAsyncThunk(
    'notes/archiveNoteAsync',
    async (noteId: string, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const token = state.auth.token;

        try {
            await archiveNote(noteId, token);
            return noteId;
        } catch (error: any) {
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ message: 'Failed to archive note' });
        }
    }
);

export const unarchiveNoteAsync = createAsyncThunk(
    'notes/unarchiveNoteAsync',
    async (noteId: string, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const token = state.auth.token;

        try {
            await unarchiveNote(noteId, token);
            return noteId;
        } catch (error: any) {
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ message: 'Failed to unarchive note' });
        }
    }
);

export const { reorderNotesLocally, resetNotesState } = notesSlice.actions;
export const notesReducer = notesSlice.reducer;
export default notesSlice;

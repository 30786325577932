import accountSlice, {
    AccountState,
    accountInitialState,
} from '@gagovictor/shared-frontend-core/dist/account/redux/accountSlice';
import authSlice, {
    AuthState,
    authInitialState,
} from '@gagovictor/shared-frontend-core/dist/auth/redux/authSlice';
import purchaseSlice, {
    PurchaseState,
    purchaseInitialState,
} from '@gagovictor/shared-frontend-core/dist/purchase/redux/purchaseSlice';
import { loadingMiddleware } from '@gagovictor/shared-frontend-core/dist/shared/redux/loadingMiddleware';
import loadingSlice, {
    LoadingState,
    loadingInitialState,
} from '@gagovictor/shared-frontend-core/dist/shared/redux/loadingSlice';
import refreshSlice, {
    RefreshState,
    refreshInitialState,
} from '@gagovictor/shared-frontend-core/dist/shared/redux/refreshSlice';
import { combineSlices, configureStore } from '@reduxjs/toolkit';

import notesSlice, {
    notesInitialState,
    NotesState,
} from '../notes/redux/notesSlice';
import preferencesSlice, {
    PreferencesState,
    preferencesInitialState,
} from '../shared/redux/preferencesSlice';
import tasksSlice, {
    TasksState,
    tasksInitialState,
} from '../tasks/redux/tasksSlice';

export const rootReducer = combineSlices(
    authSlice,
    loadingSlice,
    preferencesSlice,
    refreshSlice,
    accountSlice,
    purchaseSlice,
    tasksSlice,
    notesSlice
);

export type RootState = {
    auth: AuthState;
    loading: LoadingState;
    preferences: PreferencesState;
    refresh: RefreshState;
    account: AccountState;
    purchase: PurchaseState;
    tasks: TasksState;
    notes: NotesState;
};

export const initialState = {
    auth: authInitialState,
    loading: loadingInitialState,
    preferences: preferencesInitialState,
    refresh: refreshInitialState,
    account: accountInitialState,
    purchase: purchaseInitialState,
    tasks: tasksInitialState,
    notes: notesInitialState,
};

export function setupStore(preloadedState?: Partial<RootState>) {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(loadingMiddleware),
    });
}

export type AppDispatch = typeof store.dispatch;

const store = setupStore(initialState);

export default store;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TaskSort } from '../../tasks/models/task';

export interface PreferencesState {
    theme: 'light' | 'dark';
    language: 'en' | 'es';
    hasViewedWelcomeSlides: boolean;
    sortOption: TaskSort;
}

const getInitialTheme = (): 'light' | 'dark' => {
    const storedTheme = localStorage.getItem('theme') as 'light' | 'dark';
    if (storedTheme) {
        return storedTheme;
    }
    // Detect system preference
    const prefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)'
    ).matches;
    return prefersDark ? 'dark' : 'light';
};

const getInitialState = (): PreferencesState => ({
    theme: getInitialTheme(),
    language: 'en',
    hasViewedWelcomeSlides:
        localStorage.getItem('hasViewedWelcomeSlides') === 'true',
    sortOption:
        (localStorage.getItem('sortOption') as TaskSort) || 'createdDesc',
});

export const preferencesInitialState = getInitialState();

const preferencesSlice = createSlice({
    name: 'preferences',
    initialState: getInitialState,
    reducers: {
        toggleTheme: (state) => {
            const newTheme = state.theme === 'light' ? 'dark' : 'light';
            localStorage.setItem('theme', newTheme);
            state.theme = newTheme;
        },
        setLanguage: (state, action: PayloadAction<'en' | 'es'>) => {
            state.language = action.payload;
        },
        setHasViewedWelcomeSlides: (state) => {
            state.hasViewedWelcomeSlides = true;
            localStorage.setItem('hasViewedWelcomeSlides', 'true');
        },
        setSortOption: (
            state,
            action: PayloadAction<PreferencesState['sortOption']>
        ) => {
            state.sortOption = action.payload;
            localStorage.setItem('sortOption', action.payload);
        },
    },
});

export const {
    toggleTheme,
    setLanguage,
    setHasViewedWelcomeSlides,
    setSortOption,
} = preferencesSlice.actions;
export const preferencesReducer = preferencesSlice.reducer;
export default preferencesSlice;

import { useGlassmorphismStyles } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useGlassmorphismStyles';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { Note } from '../models/note';
import {
    deleteNoteAsync,
    archiveNoteAsync,
    unarchiveNoteAsync,
} from '../redux/notesSlice';

interface NoteCardProps {
    note: Note;
    onEdit?: (note: Note) => void;
    showSnackbar: (
        message: string,
        severity: 'success' | 'error',
        undoAction?: () => void
    ) => void;
}

export default function NoteCard({
    note,
    onEdit,
    showSnackbar,
}: NoteCardProps) {
    const dispatch = useDispatch<AppDispatch>();
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const { deleteStatus, deleteError, archiveStatus, archiveError } =
        useSelector((state: RootState) => state.notes);
    const [isContentOverflow, setIsContentOverflow] = useState(false);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();

    useEffect(() => {
        if (contentRef.current) {
            setIsContentOverflow(
                contentRef.current.scrollHeight >
                    contentRef.current.clientHeight
            );
        }
    }, [note.content]);

    const handleCloseConfirm = () => setOpenConfirm(false);

    const handleDelete = async () => {
        try {
            await dispatch(deleteNoteAsync(note.id)).unwrap();
            showSnackbar('Note deleted successfully', 'success');
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (_) {
            showSnackbar(deleteError || 'Failed to delete note', 'error');
        }
    };

    const handleArchive = async () => {
        try {
            await dispatch(archiveNoteAsync(note.id)).unwrap();
            showSnackbar('Note archived successfully', 'success', () =>
                handleUnarchive()
            );
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (_) {
            showSnackbar(archiveError || 'Failed to archive note', 'error');
        }
    };

    const handleUnarchive = async () => {
        try {
            await dispatch(unarchiveNoteAsync(note.id)).unwrap();
            showSnackbar('Note unarchived successfully', 'success');
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (_) {
            showSnackbar('Failed to unarchive note', 'error');
        }
    };

    const handleCardClick = () => {
        if (onEdit) {
            onEdit(note);
        }
    };

    const handleButtonClick = (event: React.MouseEvent, action: () => void) => {
        event.stopPropagation(); // Prevent the click event from triggering the card's onClick
        action(); // Call the specific action (archive or delete)
    };

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formattedCreatedAt = note.createdAt
        ? format(toZonedTime(note.createdAt, timeZone), 'dd/MM/yyyy HH:mm')
        : '-';

    const moreCaption = (
        <Typography
            variant="caption"
            sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                color: 'text.warning',
                mr: 2,
            }}
        >
            <UnfoldMoreIcon
                sx={{
                    fontSize: '1.5em',
                    marginRight: '4px',
                }}
            />
            More
        </Typography>
    );

    const createdAtCaption = (
        <Typography
            variant="caption"
            sx={{
                textAlign: 'right',
                color: 'text.warning',
            }}
        >
            Created at: {formattedCreatedAt}
        </Typography>
    );

    const glassStyles = useGlassmorphismStyles({
        opacity: theme.palette.mode === 'dark' ? 0.1 : 0.4,
    });

    return (
        <Box sx={{ width: '100%', position: 'relative' }}>
            <Card
                variant="outlined"
                onClick={handleCardClick}
                sx={{
                    ...glassStyles,
                    // backgroundColor: theme.palette.background.paper,
                    cursor: 'pointer',
                    userSelect: 'no-select',
                    borderColor: theme.palette.divider,
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        component="div"
                        data-testid="note-title"
                    >
                        {note.title}
                    </Typography>

                    <Typography
                        ref={contentRef}
                        sx={{
                            mb: 1.5,
                            whiteSpace: 'pre-line',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxHeight: '5em',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                        }}
                        color="text.warning"
                    >
                        {note.content ? note.content : 'No content provided'}
                    </Typography>
                    <Box
                        sx={{
                            mt: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div>{isContentOverflow && moreCaption}</div>
                        {createdAtCaption}
                    </Box>
                </CardContent>

                <CardActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Edit Note">
                        <span>
                            <Button
                                size="small"
                                color="primary"
                                onClick={(event) =>
                                    handleButtonClick(event, () => {
                                        if (onEdit) onEdit(note);
                                    })
                                }
                                data-testid="edit-note-btn"
                                aria-label="Edit Note"
                            >
                                <EditIcon />
                            </Button>
                        </span>
                    </Tooltip>
                    {note.archivedAt ? (
                        <Tooltip title="Unarchive Note">
                            <span>
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={(event) =>
                                        handleButtonClick(
                                            event,
                                            handleUnarchive
                                        )
                                    }
                                    disabled={archiveStatus === 'loading'}
                                    data-testid="unarchive-note-btn"
                                    aria-label="Unarchive Note"
                                >
                                    <UnarchiveIcon />
                                </Button>
                            </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Archive Note">
                            <span>
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={(event) =>
                                        handleButtonClick(event, handleArchive)
                                    }
                                    disabled={archiveStatus === 'loading'}
                                    data-testid="archive-note-btn"
                                    aria-label="Archive Note"
                                >
                                    <ArchiveIcon />
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip title="Delete Note">
                        <span>
                            <Button
                                size="small"
                                color="primary"
                                onClick={(event) =>
                                    handleButtonClick(event, () =>
                                        setOpenConfirm(true)
                                    )
                                }
                                disabled={deleteStatus === 'loading'}
                                data-testid="delete-note-btn"
                                aria-label="Delete Note"
                            >
                                <DeleteIcon />
                            </Button>
                        </span>
                    </Tooltip>
                </CardActions>
            </Card>

            <Dialog open={openConfirm} onClose={handleCloseConfirm}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this note?
                    </Typography>
                    <Typography variant="body1" color="error">
                        <strong>This action cannot be undone.</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>Cancel</Button>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

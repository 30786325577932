import { createTheme, Theme } from '@mui/material/styles';

// Define a color palette for both light and dark modes
const palette = {
    dark: {
        primary: {
            main: '#bedcf7',
            contrastText: '#45289a',
        },
        secondary: {
            main: '#b3ffb8',
            contrastText: '#1C6575',
        },
        background: {
            default: '#1e1e1e',
            paper: '#121212',
        },
        text: {
            primary: '#ffffff',
            secondary: '#e0e0e0',
        },
        info: {
            main: '#dae9f7',
        },
        success: {
            main: '#C2FFC6',
        },
        warning: {
            main: '#ffb74d',
            contrastText: '#1e1e1e',
        },
        error: {
            main: '#e57373',
            contrastText: '#1e1e1e',
        },
        snackbar: {
            background: 'transparent', // Updated to transparent
            textColor: '#ffffff',
        },
        textField: {
            autofillBackground: '#444E5E',
        },
        alert: {
            // Custom alert palette
            success: {
                main: '#4CAF50',
                contrastText: '#ffffff',
            },
            error: {
                main: '#f44336',
                contrastText: '#ffffff',
            },
            warning: {
                main: '#ff9800',
                contrastText: '#ffffff',
            },
            info: {
                main: '#2196f3',
                contrastText: '#ffffff',
            },
        },
    },
    light: {
        primary: {
            main: '#3a5683',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#77a6f7',
            contrastText: '#ffffff',
        },
        background: {
            default: '#f4f6f8',
            paper: '#ffffff',
        },
        text: {
            primary: '#1a1a1a',
            secondary: '#5f6368',
        },
        info: {
            main: '#3a5683',
        },
        success: {
            main: '#77a6f7',
        },
        warning: {
            main: '#ff9800',
            contrastText: '#ffffff',
        },
        error: {
            main: '#f44336',
            contrastText: '#ffffff',
        },
        snackbar: {
            background: '#323f4b',
            textColor: '#ffffff',
        },
        textField: {
            autofillBackground: '#e3f2fd',
        },
        alert: {
            // Custom alert palette for light mode
            success: {
                main: '#4CAF50',
                contrastText: '#ffffff',
            },
            error: {
                main: '#f44336',
                contrastText: '#ffffff',
            },
            warning: {
                main: '#ff9800',
                contrastText: '#ffffff',
            },
            info: {
                main: '#2196f3',
                contrastText: '#ffffff',
            },
        },
    },
};

export const getTheme = (mode: 'light' | 'dark'): Theme => {
    const currentPalette = palette[mode];

    // Dynamically update the theme-color and background-color meta tags
    const setMetaTag = (name: string, content: string) => {
        let element = document.querySelector(`meta[name="${name}"]`);
        if (!element) {
            element = document.createElement('meta');
            element.setAttribute('name', name);
            document.head.appendChild(element);
        }
        element.setAttribute('content', content);
    };

    // Set PWA background color based on the theme mode
    const pwaBackgroundColor = currentPalette.background.default;

    setMetaTag('theme-color', pwaBackgroundColor);
    setMetaTag('background-color', pwaBackgroundColor);

    // Return the MUI theme configuration
    return createTheme({
        spacing: 8,
        palette: {
            mode,
            primary: currentPalette.primary,
            secondary: currentPalette.secondary,
            background: {
                default: currentPalette.background.default,
                paper: currentPalette.background.paper,
            },
            text: currentPalette.text,
            info: currentPalette.info,
            success: currentPalette.success,
            warning: currentPalette.warning,
            error: currentPalette.error,
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        width: '100%',
                        height: '100%',
                        margin: 0,
                        padding: 0,
                        backgroundColor: currentPalette.background.default,
                        transition: 'background-color 0.3s ease',
                        overflow: 'hidden',
                    },
                    body: {
                        width: '100%',
                        height: '100%',
                        margin: 0,
                        padding: 0,
                        backgroundColor: currentPalette.background.default,
                        transition: 'background-color 0.3s ease',
                        overflowY: 'scroll',
                    },
                    '#root': {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                    input: {
                        '&:-webkit-autofill': {
                            boxShadow: `0 0 0 100px ${currentPalette.textField.autofillBackground} inset !important`,
                            transition: 'background-color 5000s ease-in-out 0s',
                            '-webkit-text-fill-color':
                                currentPalette.text.primary,
                        },
                    },
                },
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        maxWidth: '98% !important',
                        backgroundColor: 'transparent', // Use palette instead of hardcoding
                    },
                },
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent', // Set to transparent
                        color: currentPalette.snackbar.textColor,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0', // Remove padding
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        color: currentPalette.snackbar.textColor,
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 8,
                        '& .MuiAlert-icon': {
                            fontSize: '1.5rem',
                            marginRight: '12px',
                        },
                    },
                    standardSuccess: {
                        backgroundColor: currentPalette.alert.success.main,
                        color: currentPalette.alert.success.contrastText,
                    },
                    standardError: {
                        backgroundColor: currentPalette.alert.error.main,
                        color: currentPalette.alert.error.contrastText,
                    },
                    standardWarning: {
                        backgroundColor: currentPalette.alert.warning.main,
                        color: currentPalette.alert.warning.contrastText,
                    },
                    standardInfo: {
                        backgroundColor: currentPalette.alert.info.main,
                        color: currentPalette.alert.info.contrastText,
                    },
                    outlinedSuccess: {
                        borderColor: currentPalette.alert.success.main,
                        color: currentPalette.alert.success.main,
                    },
                    outlinedError: {
                        borderColor: currentPalette.alert.error.main,
                        color: currentPalette.alert.error.main,
                    },
                    outlinedWarning: {
                        borderColor: currentPalette.alert.warning.main,
                        color: currentPalette.alert.warning.main,
                    },
                    outlinedInfo: {
                        borderColor: currentPalette.alert.info.main,
                        color: currentPalette.alert.info.main,
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiInputBase-root': {
                            '& input:-webkit-autofill': {
                                '-webkit-box-shadow': `0 0 0 100px ${currentPalette.textField.autofillBackground} inset !important`,
                            },
                        },
                        '& .MuiInputBase-multiline': {
                            padding: '8px 14px',
                        },
                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: `${currentPalette.background.default}80`,
                        backdropFilter: 'blur(10px)',
                        transition: 'all .5s ease-in-out',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        minHeight: '48px',
                        padding: '8px 16px',
                        fontSize: '1rem',
                        textTransform: 'none',
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        minWidth: '48px',
                        minHeight: '48px',
                        padding: '12px',
                        borderRadius: '50%',
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        minHeight: '48px',
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
            },
        },
        typography: {
            fontFamily: 'Roboto, Arial, sans-serif',
            button: {
                fontWeight: 700,
            },
            h1: {
                fontFamily:
                    '"Uncial Antiqua", "Helvetica", "Arial", sans-serif !important',
                fontWeight: 600,
            },
            h2: {
                fontWeight: 400,
                fontSize: '2rem',
            },
            h3: {
                fontWeight: 500,
            },
            h6: {
                fontWeight: 500,
            },
            body1: {
                fontWeight: 400,
            },
            body2: {
                fontWeight: 400,
            },
        },
        shape: {
            borderRadius: 8,
        },
    });
};

export default getTheme;

import { PullToRefresh } from '@gagovictor/shared-frontend-core/dist/shared/components/PullToRefresh';
import { useAutoRefresh } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useAutoRefresh';
import AddIcon from '@mui/icons-material/Add';
import Masonry from '@mui/lab/Masonry';
import {
    Typography,
    Alert,
    Fab,
    Container,
    Snackbar,
    Button,
    Grow,
    useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppDispatch, RootState } from '../../redux/store';
import { RefreshableProps } from '../../shared/models/refreshable';
import NoteCard from '../components/NoteCard';
import NoteModal from '../components/NoteModal';
import { FetchNotesParams, Note } from '../models/note';
import { fetchNotesAsync } from '../redux/notesSlice';

const ArchivedNotesPage = ({ setRefreshAction }: RefreshableProps) => {
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { notes, fetchStatus, fetchError } = useSelector(
        (state: RootState) => state.notes
    );
    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedNote, setSelectedNote] = useState<Note>();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
    const [snackbarSeverity, setSnackbarSeverity] = useState<
        'success' | 'error'
    >('success');
    const [fetchParams] = useState<FetchNotesParams>({
        page: 1,
        limit: 20,
        filters: {
            archived: true,
        },
    });

    const fetchNotes = useCallback(async () => {
        await dispatch(fetchNotesAsync(fetchParams));
    }, [dispatch, fetchParams]);

    useEffect(() => {
        dispatch(fetchNotesAsync(fetchParams));
    }, [dispatch, fetchParams]);

    useEffect(() => {
        fetchNotes();
    }, [fetchNotes]);

    useEffect(() => {
        setRefreshAction(() => fetchNotes);

        return () => {
            setRefreshAction(undefined);
        };
    }, [fetchNotes, setRefreshAction]);

    useAutoRefresh({
        onRefresh: fetchNotes,
        interval: 60000,
        immediate: false,
        onlyWhenFocused: true,
    });

    const handleCreateNote = () => {
        setEditMode(false);
        setModalOpen(true);
    };

    const handleEditNote = (note: Note) => {
        setSelectedNote(note);
        setEditMode(true);
        setModalOpen(true);
    };

    const onCloseNoteModal = () => {
        setEditMode(false);
        setModalOpen(false);
    };

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const showSnackbar = (message: string, severity: 'success' | 'error') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const filteredNotes = notes.filter(
        (note: Note) => note.archivedAt && !note.deletedAt
    );

    return (
        <>
            <PullToRefresh onRefresh={fetchNotes}>
                <Container
                    sx={{
                        width: '100%',
                        minHeight: 'calc(100vh - 64px)', // full screen height minus footer
                        paddingTop: {
                            xs: 'calc(32px + 56px)',
                            md: 'calc(32px + 64px)',
                        }, // Offset fixed app bar/header
                        position: 'relative',
                        paddingBottom: '32px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'wrap',
                        justifyContent: 'flex-start',
                        gap: 4,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 2 }}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontFamily:
                                    '"Uncial Antiqua", "Helvetica", "Arial", sans-serif',
                                letterSpacing: '0.05em',
                                background: `linear-gradient(-60deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundSize: '200% 200%',
                                animation: 'logoGradientAnimation 20s ease infinite',
                                '@keyframes logoGradientAnimation': {
                                    '0%': { backgroundPosition: '0% 50%' },
                                    '50%': { backgroundPosition: '100% 50%' },
                                    '100%': { backgroundPosition: '0% 50%' },
                                },
                            }}
                        >
                            Notes
                        </Typography>
                        <Typography variant="h5" sx={{ mt: '0.125em' }}>(Archived)</Typography>
                    </Box>
                    
                    {fetchStatus === 'failed' && (
                        <Alert severity="error" data-testid="fetch-error-alert">
                            {fetchError}
                        </Alert>
                    )}
                    {(fetchStatus === 'succeeded' ||
                        filteredNotes.length > 0) && (
                        <Masonry
                            columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                            spacing={2}
                            data-testid="masonry"
                        >
                            {filteredNotes.map((note: Note, index: number) => (
                                <Grow
                                    key={note.id}
                                    in={true}
                                    timeout={(index + 1) * 300} // Staggered delay for animation
                                >
                                    <Box data-testid="note-card">
                                        <NoteCard
                                            note={note}
                                            onEdit={() => handleEditNote(note)}
                                            showSnackbar={showSnackbar}
                                        />
                                    </Box>
                                </Grow>
                            ))}
                        </Masonry>
                    )}
                    {fetchStatus === 'succeeded' &&
                        filteredNotes.length === 0 && (
                            <Box
                                sx={{
                                    position: 'relative',
                                    paddingBottom: '32px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    display: 'flex',
                                }}
                            >
                                <Typography variant="h6" gutterBottom>
                                    No notes archived
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/notes')}
                                    aria-label="Back to Notes"
                                >
                                    Back to Notes
                                </Button>
                            </Box>
                        )}
                </Container>
            </PullToRefresh>

            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={handleCreateNote}
            >
                <AddIcon />
            </Fab>

            <NoteModal
                open={modalOpen}
                onClose={onCloseNoteModal}
                note={selectedNote}
                mode={editMode ? 'edit' : 'create'}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ArchivedNotesPage;

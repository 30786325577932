import { Box, Typography, useTheme, Grow } from '@mui/material';
import React from 'react';

interface SlideComponentProps {
    title: string;
    description: string;
    isVisible: boolean;
}

export const SlideView: React.FC<SlideComponentProps> = ({
    title,
    description,
    isVisible,
}) => {
    const theme = useTheme();
    return (
        <Grow in={isVisible} timeout={500}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: 4,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontFamily:
                            '"Uncial Antiqua", "Helvetica", "Arial", sans-serif',
                        letterSpacing: '0.05em',
                        background: `linear-gradient(-60deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        backgroundSize: '200% 200%',
                        animation: 'logoGradientAnimation 4s ease infinite',
                        '@keyframes logoGradientAnimation': {
                            '0%': { backgroundPosition: '0% 50%' },
                            '50%': { backgroundPosition: '100% 50%' },
                            '100%': { backgroundPosition: '0% 50%' },
                        },
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        marginTop: 2,
                        color: theme.palette.text.primary,
                        textShadow: `2px 2px 4px ${theme.palette.background.default}`,
                    }}
                >
                    {description}
                </Typography>
            </Box>
        </Grow>
    );
};

import { useGlassmorphismStyles } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useGlassmorphismStyles';
import { useServerValidation } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useServerValidation';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import ShortTextIcon from '@mui/icons-material/ShortText';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    ToggleButtonGroup,
    ToggleButton,
    Grid,
    useTheme,
    Paper,
    SelectChangeEvent,
    Alert,
    Snackbar,
    FormHelperText,
    InputAdornment,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { AppDispatch, RootState } from '../../redux/store';
import { ChecklistItem } from '../models/checklist';
import { taskStatuses, Task } from '../models/task';
import { createTaskAsync, updateTaskAsync } from '../redux/tasksSlice';

import ConfirmCloseDialog from './ConfirmCloseDialog';
import DraggableChecklist from './DraggableChecklist';

export type TaskModalMode = 'create' | 'edit';

export interface TaskModalProps {
    open: boolean;
    onClose: () => void;
    mode: TaskModalMode;
    task?: Task;
}

const TaskModal: React.FC<TaskModalProps> = ({ open, onClose, mode, task }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { createStatus, updateStatus } = useSelector(
        (state: RootState) => state.tasks
    );
    const theme = useTheme();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [dateTime, setDateTime] = useState<Date | null>(null);
    const [status, setStatus] = useState(taskStatuses[0]);
    const [alignment, setAlignment] = useState<'text' | 'checklist'>('text');
    const [isChecklistMode, setIsChecklistMode] = useState(false);
    const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([
        { id: uuidv4(), text: '', completed: false },
    ]);
    const [isDirty, setIsDirty] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
    const [snackbarSeverity, setSnackbarSeverity] = useState<
        'success' | 'error'
    >('success');
    const [showClearTitle, setShowClearTitle] = useState(false);

    const isLoading =
        mode === 'create'
            ? createStatus === 'loading'
            : updateStatus === 'loading';
    const buttonLabel = mode === 'create' ? 'Create' : 'Save';
    const modalTitle = mode === 'create' ? 'New Task' : 'Edit Task';

    const { errors, setErrors, clearError, clearAllErrors } =
        useServerValidation();

    useEffect(() => {
        resetForm();
        if (mode === 'edit' && task) {
            setTitle(task.title);
            setDescription(task.description);
            setDateTime(task.dueDate ? new Date(task.dueDate) : null);
            setStatus(task.status);
            if (task.checklist && task.checklist.length > 0) {
                setIsChecklistMode(true);
                setAlignment('checklist');
                setChecklistItems(task.checklist);
            }
            setTimeout(() => {
                setIsDirty(false);
            }, 100); // Required because checklist onItemsChange marks as dirty
        }
    }, [mode, task, open]);

    const handleSubmit = async () => {
        if (!title) {
            setErrors([{ path: 'title', msg: 'Title is required' }]);
            return;
        }

        handleSnackbarClose();
        clearAllErrors();

        let dueDate: string | null = null;
        if (dateTime) {
            dueDate = dateTime.toISOString();
        }

        let checklist: ChecklistItem[] | null = null;
        let descriptionToSend = description;

        const filteredChecklist = checklistItems.filter(
            (item) => item.text.trim() !== ''
        );
        checklist = filteredChecklist;

        if (isChecklistMode) {
            descriptionToSend = '';
        } else {
            checklist = null;
        }

        try {
            if (mode === 'create') {
                await dispatch(
                    createTaskAsync({
                        title,
                        description: descriptionToSend,
                        checklist,
                        dueDate,
                        status,
                    })
                ).unwrap();
                showSnackbar('Task created successfully', 'success');
            } else if (mode === 'edit' && task) {
                await dispatch(
                    updateTaskAsync({
                        id: task.id,
                        title,
                        description: descriptionToSend,
                        checklist,
                        dueDate,
                        status: status as string,
                    })
                ).unwrap();
                showSnackbar('Task updated successfully', 'success');
            }

            resetForm();
            onClose();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error?.errors) {
                setErrors(error.errors);
            } else if (error?.message) {
                showSnackbar(error.message, 'error');
            } else {
                showSnackbar(`Failed to ${mode} task`, 'error');
            }
        }
    };

    const resetForm = () => {
        setTitle('');
        setDescription('');
        setDateTime(null);
        setChecklistItems([]);
        setIsChecklistMode(false);
        setAlignment('text');
        setStatus(taskStatuses[0]);
        setIsDirty(false);
    };

    const handleClose = () => {
        if (isDirty) {
            setShowConfirm(true);
        } else {
            onClose();
        }
    };

    const confirmClose = () => {
        setShowConfirm(false);
        onClose();
    };

    const cancelClose = () => {
        setShowConfirm(false);
    };

    const handleModeChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: 'text' | 'checklist' | null
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            const switchingToChecklist = newAlignment === 'checklist';

            if (switchingToChecklist) {
                // Switching from text to checklist
                // Convert description into checklist items
                const descriptionLines = description
                    .split('\n')
                    .filter((line) => line.trim() !== '');
                const newChecklistItems = descriptionLines.map((line) => ({
                    id: uuidv4(),
                    text: line,
                    completed: false,
                }));
                // If no items, add one empty item
                if (newChecklistItems.length === 0) {
                    newChecklistItems.push({
                        id: uuidv4(),
                        text: '',
                        completed: false,
                    });
                }
                setChecklistItems(newChecklistItems);
                setDescription(''); // Clear description
            } else {
                // Switching from checklist to text
                // Convert checklist items into description text
                const checklistTexts = checklistItems.map((item) => item.text);
                const newDescription = checklistTexts.join('\n');
                setDescription(newDescription);
                setChecklistItems([]); // Clear checklist items
            }

            setIsChecklistMode(switchingToChecklist);
            setIsDirty(true);
            // Clear any validation errors related to description or checklist
            clearError('description');
            clearError('checklist');
        }
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        setIsDirty(true);
        clearError('title');
    };

    const handleDescriptionChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDescription(e.target.value);
        setIsDirty(true);
        clearError('description');
    };

    const handleDateTimeChange = (newValue: Date | null) => {
        setDateTime(newValue);
        setIsDirty(true);
        clearError('dueDate');
    };

    const handleStatusChange = (event: SelectChangeEvent<string>) => {
        setStatus(event.target.value);
        setIsDirty(true);
        clearError('status');
    };

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const showSnackbar = (message: string, severity: 'success' | 'error') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const glassStyles = useGlassmorphismStyles({
        opacity: theme.palette.mode === 'dark' ? 0.1 : 0.7,
        blur: 20,
    });

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Paper
                    sx={{
                        width: '95%',
                        maxWidth: '540px',
                        margin: 'auto',
                        borderRadius: 1,
                        position: 'relative',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        maxHeight: {
                            xs: '95vh',
                            md: 'calc(100vh - 128px)',
                        },
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        outline: 'none',
                        ...glassStyles,
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            padding: 2,
                            pb: 0.5,
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            {modalTitle}
                        </Typography>

                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Scrollable content */}
                    <Box
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            px: 2,
                            paddingBottom: 4,
                        }}
                    >
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Title"
                            variant="outlined"
                            value={title}
                            onChange={handleTitleChange}
                            onFocus={() => {
                                setShowClearTitle(true);
                            }}
                            required
                            error={!!errors.title}
                            helperText={errors.title}
                            inputProps={{ 'data-testid': 'input-title' }}
                            InputProps={{
                                endAdornment: title && showClearTitle && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear title"
                                            edge="end"
                                            onMouseDown={(e) =>
                                                e.preventDefault()
                                            } // Prevent losing focus when clicking clear
                                            onClick={() => {
                                                setTitle('');
                                                setShowClearTitle(false);
                                            }}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <DateTimePicker
                            value={dateTime}
                            label="Due Date"
                            onChange={handleDateTimeChange}
                            views={['year', 'month', 'day', 'hours', 'minutes']}
                            slotProps={{
                                actionBar: {
                                    actions: ['clear', 'accept'],
                                },
                                textField: {
                                    fullWidth: true,
                                    error: !!errors.dueDate,
                                    helperText: errors.dueDate,
                                },
                            }}
                        />

                        {/* Grid container for Toggle Button and Status Selector */}
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                mb: isChecklistMode ? 2 : 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Grid item xs={6} sm={8}>
                                <FormControl
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.status}
                                >
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={status}
                                        onChange={handleStatusChange}
                                        label="Status"
                                        data-testid="form-control-status"
                                    >
                                        {taskStatuses.map((statusOption) => (
                                            <MenuItem
                                                key={statusOption}
                                                value={statusOption}
                                            >
                                                {statusOption
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    statusOption.slice(1)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.status && (
                                        <FormHelperText>
                                            {errors.status}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignment}
                                    exclusive
                                    onChange={handleModeChange}
                                    aria-label="Task Input Mode"
                                    fullWidth
                                    sx={{ height: '56px', mt: '8px' }}
                                >
                                    <ToggleButton
                                        value="text"
                                        data-testid="toggle-text"
                                    >
                                        <ShortTextIcon />
                                    </ToggleButton>
                                    <ToggleButton
                                        value="checklist"
                                        data-testid="toggle-checklist"
                                    >
                                        <ChecklistIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>

                        {/* Description or Checklist */}
                        {isChecklistMode ? (
                            <Box sx={{ mt: 2 }}>
                                <DraggableChecklist
                                    items={checklistItems}
                                    onItemsChange={(items) => {
                                        setChecklistItems(items);
                                        setIsDirty(true);
                                        clearError('checklist');
                                    }}
                                />
                                {errors.checklist && (
                                    <FormHelperText error>
                                        {errors.checklist}
                                    </FormHelperText>
                                )}
                            </Box>
                        ) : (
                            <Box sx={{ position: 'relative' }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={6}
                                    margin="normal"
                                    label="Description"
                                    variant="outlined"
                                    inputProps={{
                                        'data-testid': 'input-description',
                                    }}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    error={!!errors.description}
                                    helperText={errors.description}
                                />
                            </Box>
                        )}
                    </Box>

                    {/* Fixed button at the bottom */}
                    <Box
                        sx={{
                            bottom: 0,
                            padding: 2,
                            zIndex: 2,
                            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isLoading || !title}
                            aria-label={buttonLabel}
                            data-testid="button-submit"
                        >
                            {buttonLabel}
                        </Button>
                    </Box>
                </Paper>
            </Modal>

            {/* Confirmation Dialog for Unsaved Changes */}
            <ConfirmCloseDialog
                open={showConfirm}
                title="Unsaved Changes"
                description="You have unsaved changes. Are you sure you want to close?"
                onConfirm={confirmClose}
                onCancel={cancelClose}
            />

            {/* Snackbar for action feedback */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default TaskModal;

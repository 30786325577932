import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    KeyboardSensor,
    PointerSensor,
} from '@dnd-kit/core';
import {
    restrictToVerticalAxis,
    restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
    Box,
    Checkbox,
    IconButton,
    ListItem,
    TextField,
    InputAdornment,
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ChecklistItem } from '../models/checklist';

interface DraggableChecklistProps {
    items: ChecklistItem[];
    onItemsChange: (items: ChecklistItem[]) => void;
    holdDuration?: number; // Configurable hold duration in milliseconds
}

const DraggableItem: React.FC<{
    item: ChecklistItem;
    items: ChecklistItem[];
    itemRefs: React.MutableRefObject<Record<string, HTMLInputElement | null>>;
    index: number;
    isDragging: boolean;
    toggleChecklistItemCompletion: (id: string) => void;
    handleChecklistChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => void;
    handleKeyDown: (
        e: React.KeyboardEvent<HTMLDivElement>,
        index: number
    ) => void;
    removeChecklistItem: (id: string) => void;
    setFocusAfterDelete: (id: string) => void;
    isLastItem: boolean;
}> = ({
    item,
    items,
    itemRefs,
    index,
    isDragging,
    toggleChecklistItemCompletion,
    handleChecklistChange,
    handleKeyDown,
    setFocusAfterDelete,
    removeChecklistItem,
    isLastItem,
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: item.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isDragging ? '#f0f0f0' : undefined,
        'touch-action': 'none',
        cursor: 'grab',
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <ListItem disableGutters>
                {/* Checkbox */}
                <Checkbox
                    checked={item.completed}
                    onChange={() => toggleChecklistItemCompletion(item.id)}
                    inputProps={{
                        'aria-label': `Mark item ${index + 1} as completed`,
                    }}
                />

                {/* Text Field */}
                <TextField
                    value={item.text}
                    onChange={(e) => handleChecklistChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    inputRef={(el) =>
                        itemRefs ? (itemRefs.current[item.id] = el) : null
                    }
                    placeholder={`Item ${index + 1}`}
                    fullWidth
                    multiline
                    minRows={1}
                    variant="outlined"
                    inputProps={{
                        'aria-label': `Item ${index + 1} text`,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        const previousItem = items[index - 1];
                                        if (previousItem) {
                                            setFocusAfterDelete(
                                                previousItem.id
                                            );
                                        }
                                        removeChecklistItem(item.id);
                                    }}
                                    edge="end"
                                    aria-label={`Remove item ${index + 1}`}
                                    disabled={isLastItem && item.text === ''}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                {/* Drag Handle (only for dragging item) */}
                {isDragging && (
                    <IconButton>
                        <DragIndicatorIcon />
                    </IconButton>
                )}
            </ListItem>
        </div>
    );
};

const DraggableChecklist: React.FC<DraggableChecklistProps> = ({
    items,
    onItemsChange,
    holdDuration = 500,
}) => {
    const itemRefs = useRef<Record<string, HTMLInputElement | null>>({});
    const [focusAfterDelete, setFocusAfterDelete] = useState<string | null>(
        null
    );
    const [draggingId, setDraggingId] = useState<string | null>(null);

    const sensors = useSensors(
        // useSensor(MouseSensor, {
        //     activationConstraint: {
        //         delay: holdDuration,
        //         tolerance: 5,
        //     },
        // }),
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
                delay: holdDuration,
                tolerance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        // Add a new empty item if the last item's text is not empty
        if (items.length === 0 || items[items.length - 1]?.text.trim() !== '') {
            const newItem = { id: uuidv4(), text: '', completed: false };
            onItemsChange([...items, newItem]);

            setFocusAfterDelete(null);

            if (itemRefs.current[newItem.id]) {
                itemRefs.current[newItem.id]?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }
    }, [items, onItemsChange]);

    useEffect(() => {
        if (focusAfterDelete) {
            const input = itemRefs.current[focusAfterDelete];
            input?.focus();
            setFocusAfterDelete(null);
        }
    }, [items, focusAfterDelete]);

    const handleDragStart = (event) => {
        setDraggingId(event.active.id);
    };

    const handleDragEnd = (event) => {
        setDraggingId(null);

        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over.id);
            const newItems = arrayMove(items, oldIndex, newIndex);
            onItemsChange(newItems);
        }
    };

    const toggleChecklistItemCompletion = (id: string) => {
        const newItems = items.map((item) =>
            item.id === id ? { ...item, completed: !item.completed } : item
        );
        onItemsChange(newItems);
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLDivElement>,
        index: number
    ) => {
        if (e.key === 'Backspace' && items[index].text === '') {
            e.preventDefault();
            removeChecklistItem(items[index].id);
        }
    };

    const removeChecklistItem = (id: string) => {
        const newItems = items.filter((item) => item.id !== id);
        onItemsChange(newItems);
    };

    const handleChecklistChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => {
        const newItems = [...items];
        newItems[index] = { ...newItems[index], text: e.target.value };

        // Add a new empty item if the last item's text is not empty and there's no empty item
        if (
            index === items.length - 1 &&
            e.target.value.trim() !== '' &&
            !items.some((item) => item.text.trim() === '')
        ) {
            newItems.push({ id: uuidv4(), text: '', completed: false });
        }

        onItemsChange(newItems);
    };

    return (
        <Box>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
                <SortableContext
                    items={items.map((item) => item.id)}
                    strategy={verticalListSortingStrategy}
                >
                    {items.map((item, index) => (
                        <DraggableItem
                            key={item.id}
                            item={item}
                            items={items}
                            itemRefs={itemRefs}
                            index={index}
                            isDragging={draggingId === item.id}
                            toggleChecklistItemCompletion={
                                toggleChecklistItemCompletion
                            }
                            handleChecklistChange={handleChecklistChange}
                            handleKeyDown={handleKeyDown}
                            removeChecklistItem={removeChecklistItem}
                            setFocusAfterDelete={() => {}}
                            isLastItem={index === items.length - 1}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </Box>
    );
};

export default DraggableChecklist;

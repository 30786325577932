import {
    Box,
    Typography,
    Container,
    Tooltip,
    Button,
    useTheme,
} from '@mui/material';
import React from 'react';

import AnimatedMasonryBackground from '../components/AnimatedMasonryBackground';
import SwipeableDeck from '../components/SwipeableDeck';
import VideoBackground from '../components/VideoBackground';

const LandingPage: React.FC = () => {
    const theme = useTheme();

    return (
        <Box component="main">
            {/* Hero Section with Video Background */}
            <header>
                <Box
                    component="section"
                    sx={{
                        position: 'relative',
                        textAlign: 'center',
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                    }}
                >
                    <VideoBackground videoSrc="/app-preview.mp4" />
                    <Container
                        component="div"
                        maxWidth="lg"
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            py: 8,
                            borderRadius: 2,
                        }}
                    >
                        {/* Animated Logo */}
                        <Typography
                            variant="h1"
                            gutterBottom
                            sx={{
                                fontFamily:
                                    '"Uncial Antiqua", "Helvetica", "Arial", sans-serif',
                                fontSize: '3rem',
                                letterSpacing: '0.05em',
                                background: `linear-gradient(-60deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundSize: '200% 200%',
                                animation:
                                    'logoGradientAnimation 4s ease infinite',
                                '@keyframes logoGradientAnimation': {
                                    '0%': { backgroundPosition: '0% 50%' },
                                    '50%': { backgroundPosition: '100% 50%' },
                                    '100%': { backgroundPosition: '0% 50%' },
                                },
                            }}
                        >
                            Zooit
                        </Typography>

                        <Typography
                            variant="h2"
                            gutterBottom
                            sx={{
                                color: theme.palette.text.secondary,
                            }}
                        >
                            Your privacy-first,
                            <br />
                            ad-free productivity companion.
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            href="/login"
                            sx={{
                                minWidth: '200px',
                                height: '50px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                                mt: 8,
                            }}
                            aria-label="Sign up for free trial"
                        >
                            Sign Up for Free Trial
                        </Button>

                        <Typography
                            variant="body2"
                            sx={{
                                py: 2,
                                color: theme.palette.text.secondary,
                            }}
                        >
                            or
                        </Typography>

                        {/* Badge and Button Container */}
                        <Box
                            component="div"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 2,
                                flexWrap: 'wrap',
                            }}
                        >
                            {/* Google Play Badge */}
                            <a
                                href="https://play.google.com/store/apps/details?id=works.gago.tasks"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Box
                                    component="img"
                                    src="/google-play-store-badge.webp"
                                    alt="Get it on Google Play"
                                    width="219"
                                    height="65"
                                    sx={{
                                        width: '219px',
                                        height: '65px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </a>

                            {/* Apple App Store Badge */}
                            <Tooltip
                                title="Coming Soon"
                                aria-describedby="app-store-badge"
                            >
                                <Box
                                    component="img"
                                    src="/apple-app-store-badge.svg"
                                    alt="Coming Soon to the App Store"
                                    id="app-store-badge"
                                    width="193"
                                    height="65"
                                    sx={{
                                        width: '193px',
                                        height: '65px',
                                        opacity: 0.5,
                                        cursor: 'not-allowed',
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    </Container>
                </Box>
            </header>

            {/* Features Section */}
            <Box
                component="section"
                sx={{
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    py: 16,
                    minHeight: '60vh',
                    boxSizing: 'border-box',
                    [theme.breakpoints.down('sm')]: {
                        minHeight: '100vh',
                    },
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        fontFamily:
                            '"Uncial Antiqua", "Helvetica", "Arial", sans-serif',
                        letterSpacing: '0.05em',
                        background: `linear-gradient(-60deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        backgroundSize: '200% 200%',
                        animation: 'logoGradientAnimation 4s ease infinite',
                        '@keyframes logoGradientAnimation': {
                            '0%': { backgroundPosition: '0% 50%' },
                            '50%': { backgroundPosition: '100% 50%' },
                            '100%': { backgroundPosition: '0% 50%' },
                        },
                    }}
                >
                    Why Zooit?
                </Typography>

                {/* Swipeable Deck */}
                <Box
                    component="div"
                    sx={{
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mx: 'auto',
                    }}
                >
                    <SwipeableDeck />
                </Box>
            </Box>

            {/* Final CTA Section */}
            <footer>
                <Box
                    component="section"
                    sx={{
                        position: 'relative',
                        textAlign: 'center',
                        minHeight: '60vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        py: 8,
                        background: `linear-gradient(-60deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        color: theme.palette.text.primary,
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                        [theme.breakpoints.down('sm')]: {
                            minHeight: '100vh',
                        },
                    }}
                >
                    <AnimatedMasonryBackground />
                    <Container maxWidth="md" sx={{ zIndex: 1 }}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontFamily:
                                    '"Uncial Antiqua", "Helvetica", "Arial", sans-serif',
                                fontSize: '3rem',
                                letterSpacing: '0.05em',
                                background: `linear-gradient(-60deg, ${theme.palette.primary.contrastText}, ${theme.palette.secondary.contrastText}, ${theme.palette.primary.contrastText})`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundSize: '200% 200%',
                                animation:
                                    'logoGradientAnimation 4s ease infinite',
                                '@keyframes logoGradientAnimation': {
                                    '0%': { backgroundPosition: '0% 50%' },
                                    '50%': { backgroundPosition: '100% 50%' },
                                    '100%': { backgroundPosition: '0% 50%' },
                                },
                            }}
                        >
                            Ready to get started?
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                mb: 4,
                                color: theme.palette.primary.contrastText,
                                fontFamily: theme.typography.fontFamily,
                            }}
                        >
                            Discover how Zooit can transform the way you manage
                            tasks and notes.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            href="/login"
                            sx={{
                                minWidth: '200px',
                                height: '50px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                            }}
                            aria-label="Sign up for free trial"
                        >
                            Sign Up for Free Trial
                        </Button>
                    </Container>
                </Box>
            </footer>
        </Box>
    );
};

export default LandingPage;

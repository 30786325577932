import { useGlassmorphismStyles } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useGlassmorphismStyles';
import { useServerValidation } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useServerValidation';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    useTheme,
    Paper,
    Alert,
    Snackbar,
    InputAdornment,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { Note } from '../models/note';
import { createNoteAsync, updateNoteAsync } from '../redux/notesSlice';

import ConfirmCloseDialog from './ConfirmCloseDialog';

export type NoteModalMode = 'create' | 'edit';

export interface NoteModalProps {
    open: boolean;
    onClose: () => void;
    mode: NoteModalMode;
    note?: Note;
}

const NoteModal: React.FC<NoteModalProps> = ({ open, onClose, mode, note }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { createStatus, updateStatus } = useSelector(
        (state: RootState) => state.notes
    );
    const theme = useTheme();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    // const [tags, setTags] = useState([]);
    const tags = [];
    const [isDirty, setIsDirty] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
    const [snackbarSeverity, setSnackbarSeverity] = useState<
        'success' | 'error'
    >('success');
    const [showClearTitle, setShowClearTitle] = useState(false);

    const isLoading =
        mode === 'create'
            ? createStatus === 'loading'
            : updateStatus === 'loading';
    const buttonLabel = mode === 'create' ? 'Create' : 'Save';
    const modalTitle = mode === 'create' ? 'New Note' : 'Edit Note';

    const { errors, setErrors, clearError, clearAllErrors } =
        useServerValidation();

    useEffect(() => {
        resetForm();
        if (mode === 'edit' && note) {
            setTitle(note.title);
            setContent(note.content);
            setIsDirty(false);
        }
    }, [mode, note, open]);

    const handleSubmit = async () => {
        if (!title) {
            setErrors([{ path: 'title', msg: 'Title is required' }]);
            return;
        }

        handleSnackbarClose();
        clearAllErrors();

        try {
            if (mode === 'create') {
                await dispatch(
                    createNoteAsync({
                        title,
                        content,
                        tags,
                    })
                ).unwrap();
                showSnackbar('Note created successfully', 'success');
            } else if (mode === 'edit' && note) {
                await dispatch(
                    updateNoteAsync({
                        id: note.id,
                        title,
                        content,
                        tags,
                    })
                ).unwrap();
                showSnackbar('Note updated successfully', 'success');
            }

            resetForm();
            onClose();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error?.errors) {
                setErrors(error.errors);
            } else if (error?.message) {
                showSnackbar(error.message, 'error');
            } else {
                showSnackbar(`Failed to ${mode} note`, 'error');
            }
        }
    };

    const resetForm = () => {
        setTitle('');
        setContent('');
        setIsDirty(false);
    };

    const handleClose = () => {
        if (isDirty) {
            setShowConfirm(true);
        } else {
            onClose();
        }
    };

    const confirmClose = () => {
        setShowConfirm(false);
        onClose();
    };

    const cancelClose = () => {
        setShowConfirm(false);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        setIsDirty(true);
        clearError('title');
    };

    const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContent(e.target.value);
        setIsDirty(true);
        clearError('content');
    };

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const showSnackbar = (message: string, severity: 'success' | 'error') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const glassStyles = useGlassmorphismStyles({
        opacity: theme.palette.mode === 'dark' ? 0.1 : 0.7,
        blur: 20,
    });

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Paper
                    sx={{
                        width: '95%',
                        maxWidth: '540px',
                        margin: 'auto',
                        borderRadius: 1,
                        position: 'relative',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        maxHeight: {
                            xs: '95vh',
                            md: 'calc(100vh - 128px)',
                        },
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        outline: 'none',
                        ...glassStyles,
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            padding: 2,
                            pb: 0.5,
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            {modalTitle}
                        </Typography>

                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Scrollable content */}
                    <Box
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            px: 2,
                            paddingBottom: 4,
                        }}
                    >
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Title"
                            variant="outlined"
                            value={title}
                            onChange={handleTitleChange}
                            onFocus={() => {
                                setShowClearTitle(true);
                            }}
                            required
                            error={!!errors.title}
                            helperText={errors.title}
                            inputProps={{ 'data-testid': 'input-title' }}
                            InputProps={{
                                endAdornment: title && showClearTitle && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear title"
                                            edge="end"
                                            onMouseDown={(e) =>
                                                e.preventDefault()
                                            } // Prevent losing focus when clicking clear
                                            onClick={() => {
                                                setTitle('');
                                                setShowClearTitle(false);
                                            }}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {/* Content */}
                        <Box sx={{ position: 'relative' }}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={12}
                                margin="normal"
                                label="Content"
                                variant="outlined"
                                inputProps={{
                                    'data-testid': 'input-content',
                                }}
                                value={content}
                                onChange={handleContentChange}
                                error={!!errors.content}
                                helperText={errors.content}
                            />
                        </Box>
                    </Box>

                    {/* Fixed button at the bottom */}
                    <Box
                        sx={{
                            bottom: 0,
                            padding: 2,
                            zIndex: 2,
                            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isLoading || !title}
                            aria-label={buttonLabel}
                            data-testid="button-submit"
                        >
                            {buttonLabel}
                        </Button>
                    </Box>
                </Paper>
            </Modal>

            {/* Confirmation Dialog for Unsaved Changes */}
            <ConfirmCloseDialog
                open={showConfirm}
                title="Unsaved Changes"
                content="You have unsaved changes. Are you sure you want to close?"
                onConfirm={confirmClose}
                onCancel={cancelClose}
            />

            {/* Snackbar for action feedback */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default NoteModal;

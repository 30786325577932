import { Typography, useTheme } from '@mui/material';
import React from 'react';

interface LogoProps {
    animate: boolean;
    onClick: () => void;
}

export const Logo: React.FC<LogoProps> = ({ animate, onClick }) => {
    const theme = useTheme();

    return (
        <Typography
            variant="h6"
            component="div"
            onClick={onClick}
            sx={{
                // Embed the font directly using @font-face
                '@font-face': {
                    fontFamily: '"Uncial Antiqua"',
                    src: 'url("https://fonts.googleapis.com/css2?family=Uncial+Antiqua&display=swap")',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                },
                fontFamily:
                    '"Uncial Antiqua", "Helvetica", "Arial", sans-serif',
                cursor: 'pointer',
                fontSize: '28px',
                letterSpacing: '0.020em',
                textAlign: 'center',
                background: `linear-gradient(-60deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main}, ${theme.palette.primary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundSize: '200% 100%',
                animation: animate
                    ? 'gradientAnimation 1s infinite linear'
                    : 'none',
                '@keyframes gradientAnimation': {
                    '0%': {
                        backgroundPosition: '200% 50%',
                    },
                    '100%': {
                        backgroundPosition: '0% 50%',
                    },
                },
            }}
        >
            zooit
        </Typography>
    );
};

import {
    apiClient,
    logout,
    PrivacyPolicyPage,
    PurchasePage,
    setPaymentRequired,
} from '@gagovictor/shared-frontend-core';
import { AccountManagementPage } from '@gagovictor/shared-frontend-core/dist/account/pages/AccountManagementPage';
import { VerifyEmailPage } from '@gagovictor/shared-frontend-core/dist/account/pages/VerifyEmailPage';
import { LoginPage } from '@gagovictor/shared-frontend-core/dist/auth/pages/LoginPage';
import { LogoutPage } from '@gagovictor/shared-frontend-core/dist/auth/pages/LogoutPage';
import { RecoverPasswordPage } from '@gagovictor/shared-frontend-core/dist/auth/pages/RecoverPasswordPage';
import { ResetPasswordPage } from '@gagovictor/shared-frontend-core/dist/auth/pages/ResetPasswordPage';
import { SignupPage } from '@gagovictor/shared-frontend-core/dist/auth/pages/SignupPage';
import { AuthGuard } from '@gagovictor/shared-frontend-core/dist/shared/components/AuthGuard';
import { Footer } from '@gagovictor/shared-frontend-core/dist/shared/components/Footer';
import { PwaInstallPrompt } from '@gagovictor/shared-frontend-core/dist/shared/components/PwaInstallPrompt';
import { ThreeScene } from '@gagovictor/shared-frontend-core/dist/shared/components/ThreeScene';
import { Box, ThemeProvider, useMediaQuery } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { enUS } from 'date-fns/locale/en-US';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import LandingPage from './landing/pages/LandingPage';
import ArchivedNotesPage from './notes/pages/ArchivedNotesPage';
import NotesPage from './notes/pages/NotesPage';
import { RootState } from './redux/store';
import { Header } from './shared/components/Header';
import { getTheme } from './shared/config/theme';
import { WelcomeSlidesPage } from './shared/pages/WelcomeSlidesPage';
import { setHasViewedWelcomeSlides } from './shared/redux/preferencesSlice';
import TasksExceedLimitModal from './tasks/components/TasksExceedLimitModal';
import ArchivedTasksPage from './tasks/pages/ArchivedTasksPage';
import TaskBoardPage from './tasks/pages/TaskBoardPage';
import TasksPage from './tasks/pages/TasksPage';
import { resetTasksState } from './tasks/redux/tasksSlice';

function App() {
    const mode = useSelector((state: RootState) => state.preferences.theme);
    const hasViewedWelcomeSlides = useSelector(
        (state: RootState) => state.preferences.hasViewedWelcomeSlides
    );
    const isPaymentRequired = useSelector(
        (state: RootState) => state.purchase.paymentRequired
    );
    const [isFirstLaunch, setIsFirstLaunch] = React.useState(
        !hasViewedWelcomeSlides
    );

    const theme = getTheme(mode);
    const dispatch = useDispatch();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [refreshAction, setRefreshAction] = useState<
        (() => void) | undefined
    >(undefined);

    const handleWelcomeSlidesFinish = () => {
        dispatch(setHasViewedWelcomeSlides());
        setIsFirstLaunch(false);
    };

    const handlePaymentClose = () => {
        dispatch(setPaymentRequired(false));
    };

    // Centralized error handling for API requests.
    apiClient.interceptors.response.use(
        (response) => response,
        (error) => {
            const { response } = error;
            if (response) {
                if (response.status === 401) {
                    // Redirect to login if a 401 response is detected
                    dispatch(resetTasksState());
                    dispatch(logout());
                    window.location.assign('/login');
                } else if (response.status === 402) {
                    // Dispatch an action to set paymentRequired to true
                    dispatch(setPaymentRequired(true));
                }
            }
            return Promise.reject(error);
        }
    );

    return (
        <BrowserRouter>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enUS}
            >
                <ThemeProvider theme={theme}>
                    <div className="App">
                        <TasksExceedLimitModal
                            open={isPaymentRequired}
                            onClose={handlePaymentClose}
                        />

                        {isFirstLaunch && !isSmUp ? (
                            <WelcomeSlidesPage
                                slides={[
                                    {
                                        title: 'Your Favorite Productivity Companion',
                                        description:
                                            "Zooit helps you stay organized and focused, whether you're managing tasks, taking notes, or planning your day.",
                                    },
                                    {
                                        title: 'Visualize Your Day',
                                        description:
                                            'Get a clear overview of your schedule with a slick UI that shows what needs attention at a glance. See your priorities come to life.',
                                    },
                                    {
                                        title: 'Encrypted for Your Privacy',
                                        description:
                                            'Your tasks and notes are encrypted, ensuring that only you have access to your thoughts and plans. Your data belongs to you.',
                                    },
                                    {
                                        title: 'Checklists Made Simple',
                                        description:
                                            'Plan your grocery runs, packing lists, or daily tasks with intuitive checklists that keep you organized and efficient.',
                                    },
                                    {
                                        title: 'Cross-Platform',
                                        description:
                                            'Your data is always synced and backed up across devices and stored safely on the cloud.',
                                    },
                                ]}
                                onFinish={handleWelcomeSlidesFinish}
                            />
                        ) : (
                            <>
                                <Header refreshAction={refreshAction} />
                                <main>
                                    <Box
                                        sx={{
                                            color: theme.palette.text.primary,
                                            minHeight: '100vh',
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={
                                                    <TasksPage
                                                        setRefreshAction={
                                                            setRefreshAction
                                                        }
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/landing"
                                                element={<LandingPage />}
                                            />
                                            <Route
                                                path="/signup"
                                                element={<SignupPage />}
                                            />
                                            <Route
                                                path="/login"
                                                element={<LoginPage />}
                                            />
                                            <Route
                                                path="/logout"
                                                element={<LogoutPage />}
                                            />
                                            <Route
                                                path="/recover-password"
                                                element={
                                                    <RecoverPasswordPage />
                                                }
                                            />
                                            <Route
                                                path="/reset-password"
                                                element={<ResetPasswordPage />}
                                            />
                                            <Route
                                                path="/reset-password/:token"
                                                element={<ResetPasswordPage />}
                                            />
                                            <Route
                                                path="/email-verification/:token"
                                                element={<VerifyEmailPage />}
                                            />
                                            <Route
                                                path="/privacy-policy"
                                                element={
                                                    <PrivacyPolicyPage
                                                        appName={'Zooit'}
                                                        supportEmail={
                                                            'victor@gago.works'
                                                        }
                                                    />
                                                }
                                            />

                                            <Route element={<AuthGuard />}>
                                                <Route
                                                    path="/account"
                                                    // element={<AccountManagementPage setRefreshAction={setRefreshAction} />}
                                                    element={
                                                        <AccountManagementPage />
                                                    }
                                                />
                                                <Route
                                                    path="/purchase"
                                                    element={<PurchasePage />}
                                                />
                                                <Route
                                                    path="/tasks"
                                                    element={
                                                        <TasksPage
                                                            setRefreshAction={
                                                                setRefreshAction
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/tasks/archived"
                                                    element={
                                                        <ArchivedTasksPage
                                                            setRefreshAction={
                                                                setRefreshAction
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/tasks/board"
                                                    element={
                                                        <TaskBoardPage
                                                            setRefreshAction={
                                                                setRefreshAction
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/notes"
                                                    element={
                                                        <NotesPage
                                                            setRefreshAction={
                                                                setRefreshAction
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/notes/archived"
                                                    element={
                                                        <ArchivedNotesPage
                                                            setRefreshAction={
                                                                setRefreshAction
                                                            }
                                                        />
                                                    }
                                                />
                                            </Route>

                                            <Route
                                                path="*"
                                                element={
                                                    <Navigate to="/login" />
                                                }
                                            />
                                        </Routes>
                                    </Box>
                                </main>

                                {isSmUp && <Footer />}

                                <PwaInstallPrompt />
                            </>
                        )}
                        <ThreeScene />
                    </div>
                </ThemeProvider>
            </LocalizationProvider>
        </BrowserRouter>
    );
}

export default App;

import { logout, ThemeToggle } from '@gagovictor/shared-frontend-core';
import { RootState } from '@gagovictor/shared-frontend-core/dist/store';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    AppBar,
    Box,
    Toolbar,
    Button,
    useMediaQuery,
    useTheme,
    CircularProgress,
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    ListItemIcon,
    Grow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resetTasksState } from '../../tasks/redux/tasksSlice';

import { Logo } from './Logo';
import { Sidebar } from './Sidebar';

type HeaderProps = {
    refreshAction?: () => void;
};

export function Header({ refreshAction }: HeaderProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(
        (state: RootState) => state.auth.isAuthenticated
    );
    const username = useSelector(
        (state: RootState) => state.auth.user?.username
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isLoading = useSelector(
        (state: RootState) => state.loading.activeRequests > 0
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 5) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(resetTasksState());
        dispatch(logout());
        navigate('/logout');
        handleMenuClose();
    };

    const handleLoginClick = () => {
        navigate('/login');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <Box sx={{ flexGrow: 1, zIndex: 999 }}>
            <AppBar
                data-testid="app-bar"
                position="fixed"
                sx={{
                    background: scrolled
                        ? theme.palette.background.paper
                        : theme.palette.mode === 'dark'
                          ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))`
                          : `linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))`,
                    transition: 'background 0.15s ease-in-out',
                    boxShadow: 'none',
                    color: theme.palette.text.primary,
                }}
            >
                <Toolbar
                    sx={{ justifyContent: 'space-between', minHeight: 64 }}
                >
                    {/* Left Side Placeholder */}
                    <Box
                        sx={{
                            width: 100,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Sidebar />
                    </Box>

                    {/* Centered Logo */}
                    <div data-testid="app-logo">
                        <Logo animate={isLoading} onClick={handleLogoClick} />
                    </div>

                    {/* Right Side Placeholder */}
                    <Box
                        sx={{
                            width: 100,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            position: 'relative',
                        }}
                    >
                        {/* Refresh Button and Spinner (Overlapping and Aligned to Right) */}
                        <Box sx={{ position: 'relative', width: '50px' }}>
                            <Box
                                sx={{
                                    top: '50%',
                                    left: '50%',
                                    position: 'absolute',
                                    transform: 'translate(-50%, -50%)',
                                    pointerEvents:
                                        !isLoading &&
                                        refreshAction !== undefined
                                            ? 'all'
                                            : 'none',
                                }}
                            >
                                <Grow
                                    in={
                                        !isLoading &&
                                        refreshAction !== undefined
                                    }
                                >
                                    <IconButton
                                        onClick={refreshAction}
                                        color="inherit"
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                </Grow>
                            </Box>

                            <Box
                                sx={{
                                    top: '50%',
                                    left: '50%',
                                    position: 'absolute',
                                    transform: 'translate(-50%, -50%)',
                                    pointerEvents: isLoading ? 'all' : 'none',
                                }}
                            >
                                <Grow in={isLoading}>
                                    <CircularProgress
                                        color="inherit"
                                        size={24}
                                    />
                                </Grow>
                            </Box>
                        </Box>

                        {!isMobile &&
                            (isAuthenticated ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Tooltip title="Account settings">
                                        <IconButton
                                            onClick={handleAvatarClick}
                                            size="small"
                                            sx={{ ml: 2 }}
                                            aria-controls={
                                                open
                                                    ? 'account-menu'
                                                    : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                                open ? 'true' : undefined
                                            }
                                        >
                                            <Avatar
                                                sx={{ width: 32, height: 32 }}
                                            >
                                                {username
                                                    ? username
                                                          .charAt(0)
                                                          .toUpperCase()
                                                    : ''}
                                            </Avatar>
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={open}
                                        onClose={handleMenuClose}
                                        onClick={handleMenuClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform:
                                                        'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{
                                            horizontal: 'right',
                                            vertical: 'top',
                                        }}
                                        anchorOrigin={{
                                            horizontal: 'right',
                                            vertical: 'bottom',
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => navigate('/account')}
                                        >
                                            <ListItemIcon>
                                                <AccountCircleIcon fontSize="small" />
                                            </ListItemIcon>
                                            Account
                                        </MenuItem>
                                        <MenuItem onClick={handleLogout}>
                                            <ListItemIcon>
                                                <Logout fontSize="small" />
                                            </ListItemIcon>
                                            Logout
                                        </MenuItem>
                                        <MenuItem>
                                            <ThemeToggle />
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            ) : (
                                <Button
                                    color="inherit"
                                    sx={{ fontWeight: '400' }}
                                    onClick={handleLoginClick}
                                    aria-label="Login"
                                >
                                    Login
                                </Button>
                            ))}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

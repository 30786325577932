import { useGlassmorphismStyles } from '@gagovictor/shared-frontend-core/dist/shared/hooks/useGlassmorphismStyles';
import { PendingActionsOutlined } from '@mui/icons-material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
    Checkbox,
    Chip,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import React, { useState, useEffect, useRef } from 'react';

import { Task } from '../../tasks/models/task';

interface SwipeableCardProps {
    task: Task;
}

export default function MockTaskCard({ task }: SwipeableCardProps) {
    const [isDescriptionOverflow, setIsDescriptionOverflow] = useState(false);
    const descriptionRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();

    useEffect(() => {
        if (descriptionRef.current) {
            setIsDescriptionOverflow(
                descriptionRef.current.scrollHeight >
                    descriptionRef.current.clientHeight
            );
        }
    }, [task.description]);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDate = new Date();
    let formattedDueDate = 'No due date';
    let isPastDue = false;

    if (task.dueDate) {
        const dueDate = toZonedTime(task.dueDate, timeZone);
        formattedDueDate = format(dueDate, 'dd/MM/yyyy HH:mm').replace(
            ' 00:00',
            ''
        );
        isPastDue = currentDate > dueDate && task.status !== 'completed';
    }

    const formattedStatus =
        task.status.charAt(0).toUpperCase() +
        task.status.slice(1).toLowerCase();

    const isDueIn24hrs = () => {
        if (!task.dueDate) {
            return false;
        }
        const timeRemaining =
            new Date(task.dueDate).getTime() - currentDate.getTime();
        return timeRemaining > 0 && timeRemaining < 24 * 60 * 60 * 1000;
    };

    const moreCaption = (
        <Typography
            variant="caption"
            sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                color: 'text.warning',
                mr: 2,
            }}
            aria-label="View more details"
        >
            <UnfoldMoreIcon
                sx={{
                    fontSize: '1.5em',
                    marginRight: '4px',
                }}
                aria-hidden="true"
            />
            More
        </Typography>
    );

    const glassStyles = useGlassmorphismStyles({
        opacity: theme.palette.mode === 'dark' ? 0.1 : 0.4,
    });

    return (
        <Box component="section" sx={{ width: '100%', position: 'relative' }}>
            <Card
                component="article"
                variant="outlined"
                sx={{
                    ...glassStyles,
                    minHeight: '200px',
                    cursor: 'pointer',
                    userSelect: 'no-select',
                    borderColor: theme.palette.divider,
                }}
                aria-labelledby={`task-title-${task.id}`}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        component="h3"
                        data-testid="task-title"
                        textAlign="left"
                        id={`task-title-${task.id}`}
                    >
                        {task.title}
                    </Typography>
                    <Box
                        component="div"
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            my: 1,
                            gap: 1,
                            color: 'text.warning',
                        }}
                    >
                        {isPastDue ? (
                            <Chip
                                icon={<PendingActionsOutlined />}
                                label={formattedDueDate}
                                color="error"
                                sx={{
                                    backgroundColor: theme.palette.error.light,
                                    color: theme.palette.error.contrastText,
                                }}
                                data-testid="duedate-chip"
                                aria-label={`Due date: ${formattedDueDate}`}
                            />
                        ) : (
                            <Chip
                                icon={<PendingActionsOutlined />}
                                label={formattedDueDate}
                                color={isDueIn24hrs() ? 'warning' : 'default'}
                                data-testid="duedate-chip"
                                aria-label={`Due date: ${formattedDueDate}`}
                            />
                        )}
                        <Chip
                            color={
                                task.status === 'completed'
                                    ? 'success'
                                    : task.status === 'active'
                                      ? 'primary'
                                      : 'default'
                            }
                            label={formattedStatus}
                            data-testid="status-chip"
                            aria-label={`Task status: ${formattedStatus}`}
                        />
                    </Box>

                    {task.checklist && task.checklist.length > 0 ? (
                        <List aria-label="Task checklist">
                            {task.checklist.slice(0, 3).map((item) => (
                                <ListItem
                                    component="li"
                                    key={item.id}
                                    disablePadding
                                    sx={{ mt: -1.25 }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={item.completed}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': `checkbox-list-label-${item.id}`,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={`checkbox-list-label-${item.id}`}
                                        primary={item.text}
                                    />
                                </ListItem>
                            ))}
                            <Box
                                component="div"
                                sx={{
                                    mt: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    {task.checklist.length > 3 && moreCaption}
                                </div>
                            </Box>
                        </List>
                    ) : (
                        <>
                            <Typography
                                ref={descriptionRef}
                                sx={{
                                    mb: 1.5,
                                    whiteSpace: 'pre-line',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxHeight: '5em',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    textAlign: 'left',
                                }}
                                color="text.warning"
                                aria-label="Task description"
                            >
                                {task.description
                                    ? task.description
                                    : 'No description provided'}
                            </Typography>
                            <Box
                                component="div"
                                sx={{
                                    mt: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    {isDescriptionOverflow && moreCaption}
                                </div>
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
}

import { Box } from '@mui/material';
import React from 'react';

interface VideoBackgroundProps {
    videoSrc: string;
}

const VideoBackground: React.FC<VideoBackgroundProps> = ({ videoSrc }) => {
    return (
        <Box
            component="video"
            autoPlay
            loop
            muted
            playsInline
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: 0,
            }}
        >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
        </Box>
    );
};

export default VideoBackground;
